import React, { Fragment, useState }  from 'react';

import Header from '../../shared/components/header/Header';
import SubMenu from '../../shared/components/nav/subMenu';

import EventSettings from '../components/EventSettings';
import MyAccountSettings from '../components/MyAccountSettings';
import ReportSettings from '../components/ReportSettings';

import styles from './Settings.module.css'

const subMenu = [
  {
    id: 0,
    title: 'Event Settings'
  },
  {
    id: 1,
    title: 'My Details'
  },
  {
    id: 2,
    title: 'Staff Accounts'
  },
  {
    id: 3,
    title: 'Reports'
  }
]

const Settings = () => {

    const [showEventSettings, setShowEventSettings] = useState(true);
    const [showMyAccountSettings, setShowMyAccountSettings] = useState(false);
    const [showStaffAccounts, setShowStaffAccounts] = useState(false);
    const [showReports, setShowReports] = useState(false);

    const settingsSubMenuHandler = id => {
    
      console.log(id);
      switch (id) {
        case 0:
          setShowEventSettings(true);
          setShowMyAccountSettings(false);
          setShowStaffAccounts(false);
          setShowReports(false);
          break;
      case 1:
        setShowEventSettings(false);
        setShowMyAccountSettings(true);
        setShowStaffAccounts(false);
        setShowReports(false);
        break;
      case 2:
        setShowEventSettings(false);
        setShowMyAccountSettings(false);
        setShowStaffAccounts(true);
        setShowReports(false);
        break;

      case 3:
        setShowEventSettings(false);
        setShowMyAccountSettings(false);
        setShowStaffAccounts(false);
        setShowReports(true);
        break;
      
        default:
          break;
      }
     
    }

    return (
        <Fragment>
      <div className="page__wrapper">
        <Header title={'Settings'} />
        <SubMenu items={subMenu} click={settingsSubMenuHandler} />
        <div className="page-content clear-header">
          <div className={styles.contentWrapper}>
           {showEventSettings && <EventSettings />}
           {showMyAccountSettings && <MyAccountSettings />}
           {showReports && <ReportSettings />}
          </div>
        </div>
      </div>
    </Fragment>
    )
}

export default Settings;