import React from 'react';

import AnnounceItem from './AnnounceItem';
import styles from './AnnounceList.module.css';

const AnnounceList = ({ announcements, update }) => {
  return (
    <table className={styles.table}>
      <colgroup>
        <col span="1" style={{ width: '9rem' }} />
        <col span="1" style={{ width: '90%' }} />
        <col span="1" style={{ width: '9rem' }} />
      </colgroup>
      <thead className={styles.header}>
        <tr>
          <th>Live</th>
          <th>Announcement</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {announcements &&
          announcements
            .sort(compare)
            .map(item => <AnnounceItem key={item.id} announcement={item} update={update} />)}
      </tbody>
    </table>
  );
};

function compare(a, b) {
  const ADate = a.createdAt;
  const BDate = b.createdAt;

  let comparison = 0;
  if (ADate > BDate) {
    comparison = 1;
  } else if (ADate < BDate) {
    comparison = -1;
  }
  return comparison;
}

export default AnnounceList;
