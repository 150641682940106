import React from 'react';
import moment from 'moment';

import styles from './WOItem.module.css';


const WOItem = ({item}) => {

    return (
        <div className={styles.listItem}>
           <h1 className={styles.username}>{item.user.name}</h1>
           <h2 className={styles.email}>{item.user.email}</h2>
           <h3 className={styles.loggedin}>{moment(item.updatedAt).format(
                    'MMM Do, h:mm:ss a'
                  )}</h3>
        </div>
    )
}

export default WOItem;