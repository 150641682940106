import React, {Fragment, useContext, useState, useEffect} from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import Button from '../../shared/components/UIElements/Buttons/Button';

import styles from './RemoveVisitors.module.css';
import RemoveItem from './RemoveItem';

const RemoveVisitors = ({ attendees, update }) => {

    const { REACT_APP_API_URL } = process.env;
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [idsToDelete, setIdsToDelete] = useState([]);
 

    useEffect(() => {
        let idList = []
        if (attendees) {
            for (let idx = 0; attendees.length !== idx; ++idx) {
               console.log('loop');
               if (!attendees[idx].locked) {
                idList.push(attendees[idx].id)
               }
              }
             setIdsToDelete([...idList]);
             console.log(idList);
        }
    }, [attendees])

    const deleteBulkAttendees = async () => {

        console.log('List of ids to remove');
        console.log(idsToDelete);

        if (idsToDelete.length > 0) {

            try {
                const response = await sendRequest(
                `${REACT_APP_API_URL}/api/user/bulk-remove-users`,
                'DELETE',
               JSON.stringify({
                 ids:idsToDelete,
               }),
                {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + auth.token,
                }
                );
                console.log(response);
                update()
            } catch (err) {
                console.log(err);
            }
        }
    }

    const expemptId = id => {
        console.log('remove from list');
        console.log(id);

        const index = idsToDelete.indexOf(id);
        if (index > -1) {
            let updatedList = idsToDelete
            updatedList.splice(index, 1);
            setIdsToDelete([...updatedList])
        }
    }

    const addId = id => {
        console.log('add to list');
        console.log(id);
        const found = idsToDelete.find(currentId => currentId === id)
        if (!found) {
            setIdsToDelete(current => [...current, id])
        }
    }

  

    return (
        <Fragment>
        
        <div className={styles.warningWrapper}>
            <span className={styles.warningHead}>WARNING!</span>
            <span className={styles.warningBody}>This is a destructive function! Once you delete this data it cannot be retrived!!</span>
        </div>
        <div className={styles.mainAction}>
            <Button title='Delete All' className='button-outline-primary' onClick={deleteBulkAttendees} />
        </div>
        <table className={styles.table}>
        <colgroup>
       <col span="1" style={{width: "10rem"}}/>
       <col span="1" style={{width: "25%"}}/>
       <col span="1" style={{width: "20%"}}/>
       <col span="1" style={{width: "40%"}}/>
       <col span="1" style={{width: "10rem"}}/>
        </colgroup>
        <thead className={styles.header}>
            <tr>
            <th>Select</th>
            <th>Name</th>
            <th>Email</th>
            <th>Organisation</th>
            <th><FontAwesomeIcon icon={faTrashAlt}/></th>
            </tr>
        </thead>
        <tbody>
        {attendees && attendees.sort(compare).map(item =>(
            <RemoveItem key={item.id} update={update} attendee={item} expemptId={expemptId} addId={addId} />
        ))}
        </tbody>
        </table>
        </Fragment>
    )
};

function compare(a, b) {
    const ADate = a.createdAt;
    const BDate = b.createdAt;

    let comparison = 0;
    if (ADate > BDate) {
      comparison = 1;
    } else if (ADate < BDate) {
      comparison = -1;
    }
    return comparison;
  }

export default RemoveVisitors;

//