import React, { Fragment, useState, useEffect, useContext  } from 'react';
import { useSelector } from 'react-redux';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import Moderation from '../components/Moderation';
import Header from '../../shared/components/header/Header';
import SubMenu from '../../shared/components/nav/subMenu';
import CreateSession from '../components/CreateSession';
import QASessions from '../components/QASessions';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';

const subMenu = [
  {
    id: 0,
    title: 'Session List'
  },
  {
    id: 1,
    title: 'Create Session'
  }
]

const QAndA = props => {

  const evid = useSelector(state => state.eid);
    //console.log(evid);
    const { REACT_APP_API_URL } = process.env;
    const { isLoading, sendRequest, error, clearError } = useHttpClient();
    const auth = useContext(AuthContext);
    const [showSessionList, setShowSessionList] = useState(true);
    const [showAddSession, setShowAddSession] = useState(false);
    const [showModeration, setShowModeration] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [selectedSession, setSelectedSession] = useState();

    useEffect(() => {
      const fetchSessions = async () => {
        try {
          const responseData = await sendRequest(
            `${REACT_APP_API_URL}/api/qa/qa-sessions`,
            'POST',
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token,
            }
          );      
          if (responseData.sessions ) {
            console.log(responseData.sessions);
            setSessions(responseData.sessions);
            responseData.sessions.length === 0 && subMenuStateHandler(1)
          }
         
        } catch (err) {
          console.log(err);
        }
      };
      fetchSessions();
     
    }, [])

    const selectedSessionHandler = session => {

      localStorage.setItem(
        'sessionData',
        JSON.stringify({ sid: session.id })
      );
      setSelectedSession(session);
      console.log(session);
      subMenuStateHandler(2);
    }
   
    const subMenuStateHandler = (id) => {
      console.log(id);
      
      switch (id) {
        case 0:
          setShowAddSession(false);
          setShowSessionList(true);  
          setShowModeration(false);       
          break;
        case 1:
          setShowAddSession(true);
          setShowSessionList(false); 
          setShowModeration(false);       
          break;
        case 2:
          setShowModeration(true);
          setShowAddSession(false);
          setShowSessionList(false);
          break;
        default:
          break;
      }
    }

    const updateSessionStatusHandler = data => {
      console.log(data);
      // get session find session update status update state
    }


  return (
    <Fragment>
     <ErrorModal error={error} onClear={clearError} />
      <div className="page__wrapper">
        <Header title={'Q&A Sessions'} />
        <SubMenu items={subMenu} click={subMenuStateHandler} />
        <div className="page-content clear-header pc-stats">
          <div className="content-wrapper">
            {isLoading && <div className='center'><LoadingSpinner /></div>}
            {showAddSession && <CreateSession success={()=>subMenuStateHandler(0)} />}
            {!isLoading && showSessionList && <QASessions sessions={sessions} selected={selectedSessionHandler}/>}
            {showModeration && selectedSession && <Moderation session={selectedSession} updateSessionStatus={updateSessionStatusHandler} />}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default QAndA;
