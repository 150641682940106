import React, { useContext, Fragment, useState, useEffect } from 'react';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import UpdateStaffDetails from './UpdateStaffDetails';
import UpdateStaffPassword from './UpdateStaffPassword';

import './MyAccountSettings.css';

const MyAccountSettings = props => {
  const { REACT_APP_API_URL } = process.env;
  const { isLoggedIn, token, userId } = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedAccount, setLoadedAccount] = useState();

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await sendRequest(
          `${REACT_APP_API_URL}/api/staff/my-account/${userId}`,
          'POST',
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          }
        );
        console.log(response);
        if (response.result) {
          //console.log(response.tutor);
            setLoadedAccount(response.result);
        }
      } catch (err) {
        //console.log(err);
      }
    };
    getUser();
  }, [isLoggedIn]);

 

 

 

  return (
    <Fragment>
      <section id="my-account-settings">
        <div className="my-account-settings-content">
          <h1 className="my-account-settings-title">My Account Settings</h1>
          <div className="my-account-settings-inner-content">
            <div className="personal-settings-container">
             <UpdateStaffDetails staff={loadedAccount} />
            </div>
            <div className="personal-settings-container">
             <UpdateStaffPassword staff={loadedAccount} />
            </div>
          </div>
        </div>
      </section>
      <ErrorModal error={error} onClear={clearError} />

    </Fragment>
  );
};

export default MyAccountSettings;
