import React, {useContext, useState, useEffect} from 'react';

import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import styles from './DeleteSessionList.module.css';

const DeleteSessionList = ({ chosenId }) => {

    const { REACT_APP_API_URL } = process.env;
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [sessions, setSessions] = useState([]);

    useEffect(() => {

        const fetchSessions = async id => {

            try {
                const response = await sendRequest(
                  `${REACT_APP_API_URL}/api/qa/qa-sessions`,
                  'POST',
                  {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + auth.token,
                  }
                );
                console.log(response);
                response.sessions && setSessions(response.sessions)
                
              } catch (err) {
                console.log(err);
              }
             ;
        }
        fetchSessions()
    }, [])



    return (
        <table className={styles.table}>
        <colgroup>
       <col span="1" style={{width: "90%"}}/>
       <col span="1" style={{width: "10%"}}/>
        </colgroup>
        <thead className={styles.header}>
            <tr>
            <th>Session</th>
            <th>Delete</th>
            </tr>
        </thead>
        <tbody>
        {sessions && sessions.sort(compare).map(item =>(
            <tr key={item.id}>
            <td>{item.name}</td>
            <td><button onClick={()=>chosenId(item.id)} className={styles.trash}><FontAwesomeIcon icon={faTrashAlt}/></button></td>
            </tr>
        ))}
        </tbody>
        </table>
    )


}

function compare(a, b) {
    const ADate = a.createdAt;
    const BDate = b.createdAt;

    let comparison = 0;
    if (ADate > BDate) {
      comparison = 1;
    } else if (ADate < BDate) {
      comparison = -1;
    }
    return comparison;
  }

export default DeleteSessionList;