import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import Header from '../../shared/components/header/Header';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import styles from './Agenda.module.css';
import AgendaList from '../components/AgendaList';
import AddAgenda from '../components/AddAgenda';
import ContentContainer from '../../shared/components/UIElements/ContentContainer';

const Agenda = props => {
  const { REACT_APP_API_URL } = process.env;
  const history = useHistory();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const evid = useSelector(state => state.eid);
  //console.log(evid);

  const [agenda, setAgenda] = useState([]);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    const fetchAgenda = async () => {
      try {
        const response = await sendRequest(
          `${REACT_APP_API_URL}/api/agenda/all/${evid}`,
          'POST',
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        console.log(response);
        if (response.agenda) {
          if (response.agenda.length > 0) {
            setAgenda(response.agenda);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (auth.isLoggedIn) {
      evid && fetchAgenda();
    } else {
      history.push("/auth");
    }
  }, [evid, update]);

  const updateList = () => {
    setUpdate(update ? false : true);
  };

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <div className={styles.pageWapper}>
        <Header title={'Agenda'} />
        <div className="page-content clear-header pc-stats">
          <div className={styles.pageContent}>

            {isLoading && <LoadingSpinner />}
            {!isLoading && agenda && (
              <AgendaList agenda={agenda} update={updateList} />
            )}

            {!isLoading && (
              <ContentContainer title="Add Agenda Item">
                <AddAgenda eventId={evid} update={updateList} />
              </ContentContainer>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Agenda;
