import React, {useState} from 'react';

import ContentContainer from '../../shared/components/UIElements/ContentContainer';
import styles from './QASessions.module.css';

const QASessions = ({sessions, selected}) => {

    const [selectedSession, setSelectedSession] = useState()

    const selectionHandler = session => {
        setSelectedSession(session);
        selected(session);
    }

    return (
        <div className={styles.sessionsWrapper}>

        <ContentContainer title='Q&A Session List' classNameCC={styles.contentContainer}>
        <div className={styles.sessionListContainer}>
            {sessions && sessions.map(session => (
                <div key={session.id} className={`${styles.sessionItemWrapper} ${selectedSession && session.id === selectedSession.id && styles.active}`} onClick={()=>selectionHandler(session)}>
                    <div className={styles.itemName}>{session.name}</div>
                    <div className={styles.itemQuestionCount}><span className={styles.questionCount}>Questions: </span>{session.qaQuestions.length}</div>
                </div>
            ))}
        </div>
        </ContentContainer>

        </div>
    )
}

export default QASessions;