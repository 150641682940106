import React, {useState, useEffect, useContext, useRef} from 'react'
import {CSVLink} from 'react-csv';
import Input from '../FormElements/Input';

import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';
import LoadingDots from '../UIElements/LoadingDots';
import ErrorModal from '../../components/UIElements/Modal/ErrorModal';
import styles from './CSVExportQuestions.module.css';
import Button from '../UIElements/Buttons/Button';

const headers = [
    {
        label: 'Date/Time', 
        key: 'createdAt'
    },
    {
        label: 'Question', 
        key: 'question'
    },
    {
        label: 'Author Name', 
        key: 'name'
    },
    {
        label: 'Author Email', 
        key: 'email'
    },
    {
        label: 'Author Organisation', 
        key: 'organization'
    },
    {
        label: 'Selected', 
        key: 'selectedAt'
    },
    {
        label: 'Answered Live', 
        key: 'answeredAt'
    },
    {
        label: 'Approved', 
        key: 'approved'
    },
    
];


const CSVExportQuestions = ({sessionId, sessionName}) => {

    

    const { REACT_APP_API_URL } = process.env;
    const { isLoading, sendRequest, error, clearError } = useHttpClient();
    const auth = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [downloadData, setDownLoadData] = useState([]);
    const [endDate, setEndDate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [showGetLogs, setShowGetLogs] = useState(true);
    const csvLinkRef = useRef();

    const getDataHandler = async () => {

         
                try {
                    const responseData = await sendRequest(
                      `${REACT_APP_API_URL}/api/qa/questions/${sessionId}`,
                      'POST',
                    {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + auth.token,
                    }
                    );
                    if (responseData.questions){
                        console.log(responseData.questions);
                        const rawData = responseData.questions;
                        let csvData = [];

                        rawData.map(question => {
                            const newItem = {
                                createdAt: question.createdAt,
                                question: question.question,
                                name: question.user.name || 'not found',
                                email: question.user.email || 'not found',
                                organization: question.user.organization || 'not found',
                                selectedAt: question.selectedAt || 'not selected',
                                answeredAt: question.answeredAt || 'not answered live',
                                approved: question.approved ? 'approved' : 'not approved'
                            }
                            csvData.push(newItem);

                        })

                        console.log(csvData);
                        setData(()=>[...csvData]);

                        csvLinkRef.current.link.click();




                       
                        
                    }
                  } catch (err) {
                    console.log(err);
                  }
          
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.actionsWrapper}>
                
                {isLoading && <LoadingDots className={styles.dots} />}
                {!isLoading && <Button type='text' title='Export Questions CSV'  onClick={getDataHandler}/>}
                <CSVLink ref={csvLinkRef} headers={headers} data={data} filename={`${sessionName}_questions_Log.csv`} />
            </div>
        </div>
    )

}

export default CSVExportQuestions;