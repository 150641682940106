import React, {useState, useEffect, useContext} from 'react';
import Vimeo from '@u-wave/react-vimeo';
import ErrorModal from '../UIElements/Modal/ErrorModal';

import { useSelector } from 'react-redux';
import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';
import LoadingSpinner from '../UIElements/LoadingSpinner';
import VimeoPlayer from '../Video/VimeoPlayer';
import styles from './StreamPreview.module.css';
import VideoPlaceHolder from './VideoPlaceHolder';

const StreamPreview = () => {

    const evid = useSelector(state => state.eid);
    const { REACT_APP_API_URL } = process.env;
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [streams, setStreams] = useState([]);
    const [streamNotFound, setStreamNotFound] = useState(false);

   useEffect(() => {
       const fetchStreams = async () => {
        try {
            const response = await sendRequest(
              `${REACT_APP_API_URL}/api/streams/`,
              'POST',
              JSON.stringify({
                eventId: evid
              }),
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token,
              }
            );
            console.log(response);
            if (response) {
                if (response.streams) {
                    setStreams(response.streams);
                    if (response.streams.length === 0) {
                        setStreamNotFound(true);
                    } else {
                        setStreamNotFound(false);
                    }
                }
            }
            
          } catch (err) {
            console.log(err);
          }

       }
       evid && fetchStreams();
   }, [])



    return (
        <section id={styles.streamPreviewWrapper}>
        <div className={styles.container}>
            <ErrorModal error={error} clearError={clearError} />
            <div className={styles.previewHeader}>
                <h1 className={styles.mainTitle}>Stream Preview</h1>
            </div>
            {isLoading && <LoadingSpinner />}
            {!isLoading && streams.length > 0 && <Vimeo className={styles.player} video={streams[0].uri} responsive={true}  width={1066} height={500}/>}
            {!isLoading && streamNotFound && <VideoPlaceHolder />}
            </div>
        </section>
    )
}

export default StreamPreview;