import React, {useEffect, useState, useContext} from 'react'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import ESCheckbox from '../../shared/components/FormElements/ESCheckbox';
import LoadingDots from '../../shared/components/UIElements/LoadingDots';

import  styles from './AnnounceItem.module.css';



const AnnounceItem = ({ announcement, update}) => {

    const { REACT_APP_API_URL } = process.env;
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [isLive, setIsLive] = useState(false);

    useEffect(() => {
       setIsLive(announcement.live)
    }, [announcement])

    const setStatus = async toggleState => {

        try {
            const response = await sendRequest(
              `${REACT_APP_API_URL}/api/news/toggle`,
              'PATCH',
              JSON.stringify({
                id:announcement.id,
                live: toggleState
              }),
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token,
              }
            );
            console.log(response);
            setIsLive(toggleState);
            
          } catch (err) {
            console.log(err);
          }

    }

    const deleteItem = async id => {

        try {
            const response = await sendRequest(
              `${REACT_APP_API_URL}/api/news/delete/${id}`,
              'DELETE',
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token,
              }
            );
            console.log(response);
            update()
        } catch (err) {
            console.log(err);
        }

    }

    const announcementLive = data => {
          setStatus(data);
    }


    return (
        <tr key={announcement.id}>
            <td>{isLoading ? <LoadingDots className={styles.dots} /> : <ESCheckbox id={announcement.id} style={{transform: 'scale(-0.6) rotate(180deg)'}} state={announcementLive} toggle={isLive} />}</td>
            <td>{announcement.message}</td>
            <td className={styles.center}><button onClick={()=>deleteItem(announcement.id)} className={styles.trash}><FontAwesomeIcon icon={faTrashAlt}/></button></td>
        </tr>
    )
}

export default AnnounceItem;