import React, { useEffect, useState, useContext } from 'react';

import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';

import Input from '../FormElements/Input';
import Button from '../UIElements/Buttons/Button';
import LoadingSpinner from '../UIElements/LoadingSpinner';
import ErrorModal from '../UIElements/Modal/ErrorModal';

import './CreatePollForm.css';

var optionList = [
  {
    id: 1,

  },
  {
    id: 2,
  },
];

const CreatePollForm = ({ eventId, success }) => {

  const { REACT_APP_API_URL } = process.env;
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [options, setOptions] = useState([]);
  const [update, setUpdate] = useState(false);
  const [optionValues, setOptionValues] = useState([]);
  const optionLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  

  const addOptionHandler = () => {
    if (options.length < 26) {
      setUpdate(update ? false : true);
      const newId = options.length + 1;
      var newArray = options;
      newArray.push({ id: newId });
      optionList = newArray;
    }
  };

  const removeOptionHandler = () => {
    setUpdate(update ? false : true);
    if (options.length > 2) {

      //remove any data from input
      const label = optionLetters.charAt(options.length - 1)
      console.log(label);
      const itemIdx = optionValues.findIndex((item) => {
        return item.label === label
      })
      
      //update DOM
      if (itemIdx > 0) {
        const optionData = optionValues
        optionData.splice(itemIdx, 1)
        setOptionValues(optionData)
      }

      var newArray = options;
      newArray.pop();
      optionList = newArray;

    }
  };

  const optionChangeHandler = event => {

    const optionData = optionValues

    const  option = {label : event.target.name, value : event.target.value }
    console.log(option);

    const currentOption = optionData.find(item => {
        return item.label === option.label
    });

    console.log(currentOption);

    if (currentOption) {
      currentOption.label = option.label
      currentOption.value =  option.value
    } else {
      optionData.push(option)
    }
    
    setOptionValues(optionData);
    console.log(optionData);

    

    
  }

  useEffect(() => {
    setOptions(optionList);
  }, [update]);

  const onSubmit = async event => {
    event.preventDefault();

    console.log(event.target.question.value);
    console.log(optionValues);
    //console.log(eventId);

    clearError();

    try {
      const response = await sendRequest(
        `${REACT_APP_API_URL}/api/poll/create-poll`,
        'POST',
        JSON.stringify({
          question: event.target.question.value,
          options: optionValues,
          eventId: eventId,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      console.log(response);
      success();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="create-poll-form-wrapper">
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <form onSubmit={onSubmit}>
          <Input
            id="question"
            name="question"
            type="text"
            placeholder="required"
            groupFront="Question:"
          />

          <div className="poll-options">
            {options.map(option => (
              <div key={option.id} className="option-container">
                <Input
                  key={option.id}
                  id={`option${option.id}`}
                  name={optionLetters.charAt(option.id - 1)}
                  type="text"
                  placeholder={option.id > 2 ? '' : 'required'}
                  groupFront={`Option ${optionLetters.charAt(option.id - 1)}`}
                  onChange={optionChangeHandler}
                />
              </div>
            ))}
          </div>

          <div className="create-poll-actions">
            <Button title="Add Option" type='button' onClick={addOptionHandler} />
            <Button title="Remove Option"  type='button' onClick={removeOptionHandler} />
            <Button title="Create Poll"  type='submit' className="button-secondary" />
          </div>
        </form>
      )}
    </div>
  );
};

export default CreatePollForm;
