import React, {useState, useEffect, useContext} from 'react';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/UIElements/Buttons/Button';

import styles from './AddAgenda.module.css';

const AddAgenda = ({eventId, update}) => {

    const { REACT_APP_API_URL } = process.env;
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    const submitHandler = async event => {
        event.preventDefault()

        console.log(event.target);

        const startTime = event.target.startTime.value;
        const endTime = event.target.endTime.value;
        const title = event.target.title.value;
        const subTitle = event.target.subTitle.value || '';
        const desc = event.target.desc.value || '';

        //console.log(eventId);
        console.log(startTime);
        console.log(endTime);
        console.log(title);
        console.log(desc);
        
    

           clearError();
       
           try {
             const response = await sendRequest(
               `${REACT_APP_API_URL}/api/agenda/create`,
               'POST',
               JSON.stringify({
                 eventId:eventId,
                 startTime: startTime,
                 endTime: endTime,
                 title: title,
                 subTitle: subTitle,
                 description: desc,
               }),
               {
                 'Content-Type': 'application/json',
                 Authorization: 'Bearer ' + auth.token,
               }
             );
             console.log(response);
             update()
             
           } catch (err) {
             console.log(err);
           }
         };

    

    return (
        <form className={styles.addAdgendWrapper} onSubmit={submitHandler}>
            <div className={styles.row}>
                <div className={styles.fieldWrapper}>
                    <label className={styles.label}>Start<span className={styles.required}>* Required!</span></label>
                    <Input id='startTime' name='startTime' inputClassName={`no-group ${styles.input}`} type='time'/>
                </div>
                <div className={styles.fieldWrapper}>
                    <label className={styles.label}>End<span className={styles.required}>* Required!</span></label>
                    <Input id='endTime' name='endTime' inputClassName={`no-group ${styles.input}`} type='time'/>
                </div>
                <div className={`${styles.fieldWrapper} ${styles.title}`}>
                    <label className={styles.label}>Title <span className={styles.required}>* Required!</span></label>
                    <Input id='title' name='title' inputClassName={`no-group ${styles.input}`} type='text'/>
                </div>

                <div className={`${styles.fieldWrapper} ${styles.title}`}>
                    <label className={styles.label}>Sub Title / Speaker</label>
                    <Input id='subTitle' name='subTitle'  inputClassName={`no-group ${styles.input}`} type='text'/>
                </div>
            </div>

            <div className={styles.row}>
                <div className={`${styles.fieldWrapper} ${styles.desc} `}>
                    <label className={styles.label}>Description</label>
                    <textarea id='desc' name='desc' className={`no-group ${styles.textArea}`} rows="4" />
                </div>
            </div>

            <div className={styles.actions}>
                <Button className={`button-secondary`} type="submit" title='Add' />
            </div>
        </form>
    )
}

export default AddAgenda;