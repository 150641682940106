import React, { useEffect, useState, useContext } from 'react';

import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';


import Input from '../FormElements/Input';
import Button from '../UIElements/Buttons/Button';
import ErrorModal from '../UIElements/Modal/ErrorModal';

import styles from './QAReply.module.css';

const QAReply = ({success, cancel, qid}) => {

    const { REACT_APP_API_URL } = process.env;
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    


    const onSubmit = async (event) => {
     event.preventDefault()
      
        clearError();
    
        try {
          const response = await sendRequest(
            `${REACT_APP_API_URL}/api/qa/answer/${qid}`,
            'POST',
            JSON.stringify({
              answer: event.target.message.value,
              authorId: auth.userId,
            }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token,
            }
          );
          console.log(response);
          success()
        } catch (err) {
          console.log(err);
        }
      };

      return (
          <div className={styles.replayWrapper}>
            <ErrorModal error={error} onClear={clearError} />
            {!isLoading && 
                <form className={styles.form} onSubmit={onSubmit} >
                <Input
                    id="message"
                    name="message"
                    inputClassName = "no-group"
                    type="textarea"
                    placeholder="message"
                    autoFocus
                    autoComplete="off"
                />
                <div className='create-event-actions'>
                    <Button type='button' title='Cancel' className='button-primary' onClick={cancel}/>
                    <Button type='submit' title='Send' className='button-secondary' />
                </div>
                </form>
            }
          </div>
      )
}

export default QAReply;