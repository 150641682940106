import React from 'react';

import { NavLink } from 'react-router-dom'; 
import { useLocation } from 'react-router-dom';


import './mainNav.css';

 const MainNav = () => {

    const location = useLocation()
    const url = location.pathname;

    return (
        <div className="main-nav__wrapper">
           { url === '/' ? 
           <ul className="nav-links">
                <li>
                    <NavLink to="/">Events</NavLink>
                </li>
            </ul>
            :
            <ul className="nav-links">
                <li>
                    <NavLink to={`/dashboard`}>Dashboard</NavLink>
                </li>
                <li>
                    <NavLink to={`/agenda`}>Agenda</NavLink>
                </li>
                <li>
                    <NavLink to={`/announcements`}>Announcements</NavLink>
                </li>
                <li>
                    <NavLink to={`/qa-sessions`}>Q&A Sessions</NavLink>
                </li>
                <li>
                    <NavLink to={`/polls`}>Polls</NavLink>
                </li>
                <li>
                    <NavLink to={`/attendees`}>Attendees</NavLink>
                </li>
                <li>
                    <NavLink to={`/stats`}>Stats</NavLink>
                </li>
                <li>
                    <NavLink to={`/settings`}>Settings</NavLink>
                </li>
                
            </ul>
            }
        </div>
    )
}


export default MainNav;