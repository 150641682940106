import React, {useEffect, useState} from 'react';

import styles from './VMixUriItem.module.css';


const VMixUriItem = ({session}) => {

    const { REACT_APP_API_URL } = process.env;
    const [copied, setCopied] = useState('Click URI to copy to clipboard!');
    const [updated, setUpdated] = useState(false);

    const copy = () => {

        navigator.clipboard.writeText(`${REACT_APP_API_URL}/api/feed/vmix/${session.id}`)
        setCopied('Copied!');
        setUpdated(updated ? false : true);

    }

    useEffect(() => {
        setTimeout(() => {
            setCopied('Click URI to copy to clipboard!');
        }, 5000)
      }, [updated])


    return (
        <div className={styles.wrapper}>
         <div className={styles.titleConatiner}>
             {session.name}
         </div>
         <div className={styles.uriContainer} onClick={copy}>
         {`${REACT_APP_API_URL}/api/feed/vmix/${session.id}`}
         <p className={styles.notice}>{copied}</p>
         </div>

         

        </div>
    )
}

export default VMixUriItem;
