import React, { useState, Fragment, useEffect, useContext } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import './VisitorItem.css';
import Button from '../../shared/components/UIElements/Buttons/Button';
import ESCheckbox from '../../shared/components/FormElements/ESCheckbox';
import DeleteLock from './DeleteLock';

const VisitorItem = ({ visitor, update, eventId }) => {
  
  const { REACT_APP_API_URL } = process.env;

  const auth = useContext(AuthContext);
  const [showDetails, setShowDetails] = useState(false);
  const [ticketNo, setTicketNo] = useState('');
  const [emailStatus, setEmailStatus] = useState('Resend ticket email');
  const [isSuspended, setIsSuspended] = useState();
  const [registered, setRegistered] = useState(false);
  const [approved, setApproved] = useState(visitor.approved);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    if (visitor.ticketNo) {
      setTicketNo(visitor.ticketNo);
    } else {
      setTicketNo('No Ticket Assigned');
    }

    if (visitor.suspended) {
      setIsSuspended(true);
    } else {
      setIsSuspended(false);
    }

    if (visitor.registered) {
      setRegistered(true);
    }

    setApproved(visitor.approved)
  }, [visitor]);

  const rollTicket = async id => {
    try {
      const responseData = await sendRequest(
        `${REACT_APP_API_URL}/api/user/roll-ticket`,
        'POST',
        JSON.stringify({
          userId: id,
          eventId:eventId
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );

      if (responseData) {
        console.log(responseData.result);
        setTicketNo(responseData.result.ticketNo);
        setIsSuspended(false);
        setRegistered(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const resendTicket = async id => {
    try {
      const responseData = await sendRequest(
        `${REACT_APP_API_URL}/api/user/resend-ticket-email`,
        'POST',
        JSON.stringify({
          userId: id,
          eventId: eventId

        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      console.log(responseData);
      if (responseData.message) {
        setEmailStatus('email resent');
      } else {
        console.log(responseData.mesage);
        setEmailStatus('Error - not sent');
      }
    } catch (err) {
      setEmailStatus('Error - not sent');
      console.log(err);
    }
  };

  const suspend = async id => {
    try {
      const responseData = await sendRequest(
        `${REACT_APP_API_URL}/api/user/suspend-user`,
        'POST',
        JSON.stringify({
          userId: id,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      console.log(responseData);
      if (responseData.success) {
        setIsSuspended(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const unsuspend = async id => {
    try {
      const responseData = await sendRequest(
        `${REACT_APP_API_URL}/api/user/unsuspend-user`,
        'POST',
        JSON.stringify({
          userId: id,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      console.log(responseData);
      if (responseData.success) {
        setIsSuspended(false);
      }
    } catch (err) {
      setEmailStatus('Error - not sent');
      console.log(err);
    }
  };


  const userAccountApproved = async isApproved => {
    console.log(visitor.id +  ' ' + isApproved);
    setApproved(isApproved)
    try {
      const responseData = await sendRequest(
        `${REACT_APP_API_URL}/api/user/approve-user/${visitor.id}`,
        'PATCH',
        JSON.stringify({
          approved: isApproved,
          eventId:eventId
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );

      console.log(responseData);
      if (responseData.data) {
        setTicketNo(responseData.data.ticketNo)
      }
     
    } catch (err) {
      console.log(err);
      setApproved(false);
    }
    
  }

  const selected = () => {
    showDetails ? setShowDetails(false) : setShowDetails(true);
  };

  return (
    <Fragment>
    
      <div
        className="visitor-item__wrapper"
        onClick={() => selected(visitor.id)}
      >
      
        <div>
          {approved ? (
            <span className="vi-registered">
              <FontAwesomeIcon icon="check-circle" />
            </span>
          ) : (
            <span className="vi-unregistered">
              <FontAwesomeIcon icon="times-circle" />
            </span>
          )}
        </div>
        <div className="user-info-box">
          <h2 className="visitor-item-name">{visitor.name}</h2> 
          <h2 className="visitor-item-email">{visitor.email}</h2>
        </div>
        <div className="user-org-box">
        {visitor.organization && <span className="visitor-org-name-label">Organisation:</span>}
          <h2 className="visitor-org-name">{visitor.organization}</h2> 
        </div>
        <div className="last-logged-box">
          <h2 className="visitor-item-last-logged">
            Last Logged in:{' '}
            <span className="loggedin-res">
              {visitor.registered
                ? `${moment(visitor.lastLoggedIn).format(
                    'MMMM Do YYYY, h:mm:ss a'
                  )}`
                : 'Never'}
            </span>{' '}
          </h2>
        </div>
        <div> 
          <ESCheckbox id={visitor.id} style={{transform: 'scale(-0.6) rotate(180deg)'}} state={userAccountApproved} toggle={approved} checkType={true} />
        </div>
      </div>
      {showDetails && (
        <div className="vd-wrapper">
          <div className="vd-login-count">
            {visitor.registered && <h1>Login count: {visitor.logginCount}</h1>}
            <div>
          
        </div>
          </div>
          <div className="vd-ticket_box">
            <h1 className="vd-ticket-no">{ticketNo}</h1>
            {ticketNo === 'No Ticket Assigned' ? (
              <Button
                title="assign ticket"
                onClick={() => rollTicket(visitor.id)}
              />
            ) : (
              <div>
             
                <Button
                  title={emailStatus}
                  onClick={() => resendTicket(visitor.id)}
                />
                <Button
                  title="roll ticket"
                  onClick={() => rollTicket(visitor.id)}
                />
                {!isSuspended ? (
                  <Button
                    className="button-danger"
                    title="suspend ticket"
                    onClick={() => suspend(visitor.id)}
                  />
                ) : (
                  <Button
                    className="button-warning"
                    title="unsuspend ticket"
                    onClick={() => unsuspend(visitor.id)}
                  />
                )}
               
              </div>
             
            )}
            <DeleteLock id={visitor.id} update={update} isLocked={visitor.locked} />
          </div>
        </div>
      )}
       
    </Fragment>
  );
};

export default VisitorItem;
