import React, { useState, Fragment, useEffect, useContext } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faExclamationTriangle, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import PollChart from '../../shared/components/Charts/PollChart';
import Switch from '../../shared/components/UIElements/Switch';
import ESCheckbox from '../../shared/components/FormElements/ESCheckbox';
import './PollItem.css';
import Button from '../../shared/components/UIElements/Buttons/Button';

import LoadingDots from '../../shared/components/UIElements/LoadingDots';

const PollListItem = ({ poll, deleted }) => {
  const { REACT_APP_API_URL } = process.env;

  const auth = useContext(AuthContext);
  const { isLoading, sendRequest } = useHttpClient();
  const [menuOpen, setMenuOpen] = useState(false);
  const [pollData, setPollData] = useState();
  const [pollOpen, setPollOpen] = useState(false);
  const [checked, setChecked] = useState('');
  const [chartWidth, setChartWidth] = useState('30rem');


  useEffect(() => {
    poll && setPollData(poll);
    poll && setCustomWidth(poll);
    poll && setPollOpen(poll.open);
    poll && poll.open && setChecked('checked');
  }, [])

  const setCustomWidth = data => {
    const columnCount = data.pollElements.length;
     const remWidth = (columnCount * 50) / 10;

     if ((columnCount > 5 ) && (columnCount < 7) ) {
        setChartWidth(`${remWidth + 50}rem`);
     } else if (columnCount >= 7) {
      setChartWidth(`${remWidth}rem`);
     }
  }

  const resetPoll = async id => {

    try {
      const responseData = await sendRequest(
        `${REACT_APP_API_URL}/api/poll/reset/${poll.id}`,
        'PATCH',
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      if (responseData.poll) {
        console.log(responseData.poll);
        setPollData(responseData.poll);
        setMenuOpen(false);
      }
    } catch (err) {
      console.log(err);
    }

  }

  const deletePoll = async () => {

    try {
      const responseData = await sendRequest(
        `${REACT_APP_API_URL}/api/poll/${poll.id}`,
        'DELETE',
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      if (responseData.message) {
        console.log(responseData.message);
        deleted()
      }
    } catch (err) {
      console.log(err);
    }

  }

  const togglePoll = async isOpen => {
    setPollOpen(isOpen)
    try {
      await sendRequest(
        `${REACT_APP_API_URL}/api/poll/toggle/${poll.id}`,
        'PATCH',
        JSON.stringify({
          open: isOpen
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      
    } catch (err) {
      console.log(err);
      setPollOpen(false);
    }
    
  }

  const refreshData = async () => {

    try {
      const responseData = await sendRequest(
        `${REACT_APP_API_URL}/api/poll/poll/${poll.id}`,
        'POST',
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      if (responseData.poll) {
        console.log(responseData.poll);
        setPollData(responseData.poll);
        setMenuOpen(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Fragment>
    
      <div className="poll-item__wrapper" >

      {isLoading && <div className='poll-item-loading'>
        <LoadingDots className='poll-item-loading-dots'/>
        <h1  className='poll-item-loading-dots-h1'>updating</h1>
        <p  className='poll-item-loading-dots-p'>please wait</p>
      </div>}

        <div className={`poll-item-menu ${menuOpen ? 'pim-open': ''}`}>
          <div className='pim-close' onClick={()=> menuOpen ? setMenuOpen(false) : setMenuOpen(true)}>
            close
          </div>
          <div className='pim-warning'>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <h2 className='pim-warning-h2'>WARNING!</h2>
            <p className='pim-warning-p'>These processes cannot be undone. Please make sure you have recorded all necessary data.</p>
          </div>
          <div className='pim-actions'>
            <Button className="button-warning" title="Reset Poll" onClick={resetPoll}/>
            <Button className="button-danger" title="Delete Poll" onClick={deletePoll}/>
          </div>
        </div>

        <div className='pim-show-menu'>
        <span className='pim-show-menu-refresh' onClick={refreshData}> 
            <FontAwesomeIcon icon={faSyncAlt} />
          </span>
          <span className='pim-show-menu-selector' onClick={()=> menuOpen ? setMenuOpen(false) : setMenuOpen(true)}> 
            <FontAwesomeIcon icon={faEllipsisH} />
          </span>
        </div>
        <div className="poll-body-continer" style={{ width: chartWidth}}>
         {pollData && <PollChart poll={pollData} />}
        </div>

        <div className='pim-total-votes'>

        </div>

        <div className="poll-item-footer">
        <div className='poll-item-footer-toggle'>
          {/* <Switch id={poll.id} active={togglePoll} checked={pollOpen} /> */}
          <ESCheckbox state={togglePoll} toggle={pollOpen} checkType={false} style={{margin: '1rem 0', transform: 'scale(0.8, 0.8)'}}/>
            {pollOpen ? <p className='poll-item-footer-p pif-open'>POLL OPEN</p> : <p className='poll-item-footer-p pif-closed'>POLL CLOSED</p>}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PollListItem;
