import React, {useState, useEffect, useContext} from 'react';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/UIElements/Buttons/Button';
import LoadingDots from '../../shared/components/UIElements/LoadingDots';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';

import styles from './AddAnnouncment.module.css';


const AddAnnouncment = ({eventId, update}) => {

    const { REACT_APP_API_URL } = process.env;
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    const submitHandler = async event => {
        event.preventDefault()

        const message = event.target.message.value;
        console.log(message);
        clearError();
       
           try {
             const response = await sendRequest(
               `${REACT_APP_API_URL}/api/news/create`,
               'POST',
               JSON.stringify({
                 eventId:eventId,
                 message: message
               }),
               {
                 'Content-Type': 'application/json',
                 Authorization: 'Bearer ' + auth.token,
               }
             );
             console.log(response);
             update()
             
           } catch (err) {
             console.log(err);
             update()
           }
         };

    return (
        <form className={styles.addAdgendWrapper} onSubmit={submitHandler}>
            <ErrorModal error={error} onClear={clearError} />
            <div className={styles.row}>
                <div className={`${styles.fieldWrapper} ${styles.title}`}>
                    <label className={styles.label}>Announcment <span className={styles.required}>* Required!</span></label>
                    <Input id='message' name='message' inputClassName={`no-group ${styles.input}`} type='text'/>
                </div>
                {!isLoading && <Button className={`button-secondary`} type="submit" title='Add' />}
                {isLoading && <LoadingDots className={styles.dots}/>}
               
            </div>
        </form>
    )
}

export default AddAnnouncment;