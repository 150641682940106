import React from 'react';
import moment from 'moment';

import styles from './ModerationAnswerList.module.css';

const ModerationAnswerList = ({answers}) => {

    return (
        <div className={styles.aContainer}>
            <div className={styles.aBody}>
                {answers.answer}
            </div>
            <div className={styles.aInfo}>
                <div className={styles.aAuthor}>
                    <p>Admin</p>
                </div>
                <div className={styles.aTimestamp}>
                    <p>{moment(answers.createdAt).format('LT')}</p>
                </div>
            </div>
        </div>
    )
}


export default ModerationAnswerList;