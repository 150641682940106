import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState} from 'react'; 

import VMixURIList from '../../qAndA/components/VMixInfo';
import EditEventForm from '../../shared/components/Forms/EditEventForm';
import UpdateLiveStream from '../../shared/components/Forms/UpdateLiveStream';
import ContentContainer from '../../shared/components/UIElements/ContentContainer';
import DeleteSession from './DeleteSession';

import styles from './EventSettings.module.css';
import GlobalActions from './GlobalActions';


const EventSettings = () => {

    const [updateSessions, setUpdateSessions] = useState(false);

    return (
        <section id={styles.eventSettingsWrapper}>
            <div className={styles.groupWrapper}>
                <ContentContainer title='Event Details'>
                    <EditEventForm />
                </ContentContainer>
            </div>
            <div className={styles.groupWrapper}>
                <ContentContainer title='Live Stream'>
                    <UpdateLiveStream />
                </ContentContainer>
            </div>
            <div className={styles.groupWrapper}>
                <ContentContainer title='vMix Q&A URI List' >
                    <VMixURIList update={updateSessions} />
                </ContentContainer>
            </div>
            
            <div className={styles.groupWrapper}>
                <div className={styles.warningText}>
                    <h1><FontAwesomeIcon icon={faExclamationTriangle} /> WARNING! - These are destructive actions that cannot be undone! Make sure you have backed up any data you require.</h1>
                </div>
                <ContentContainer title='Q&A Sessions' >
                    <DeleteSession udateSessions={()=>setUpdateSessions(state => !state) }/>
                </ContentContainer>
                <GlobalActions />
            </div>
        </section>
    )
}

export default EventSettings;