import React from 'react';
import CreateSessionForm from '../../shared/components/Forms/CreateSessionForm';
import ContentContainer from '../../shared/components/UIElements/ContentContainer';

import styles from './CreateSession.module.css';

const CreateSession = ({success}) => {

    return (
        <div className={styles.formWrapper}>
        <ContentContainer title='Create a Q&A Session'>
            <CreateSessionForm success={success}/>
        </ContentContainer>
        </div>
    )
}

export default CreateSession;