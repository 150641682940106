import React from 'react';
import CSVExportAttendance from '../../shared/components/CSVExport/CSVExportAttendance';
import ContentContainer from '../../shared/components/UIElements/ContentContainer';
import QAList from './QAList';

import styles from './ReportSettings.module.css';

const ReportSettings = () => {

    return (
        <section id={styles.reportWrapper}>
        <div className={styles.groupWrapper}>
                <ContentContainer title='CSV Attendance Report'>
                    <CSVExportAttendance /> 
                </ContentContainer>
            </div>

            <div className={styles.groupWrapper}>
                <ContentContainer title='CSV Q&A Report'>
                    <QAList /> 
                </ContentContainer>
            </div>
        </section>
    )
}

export default ReportSettings;