import React, {Suspense,Fragment} from 'react';
import { SocketContext, socket } from './shared/context/socket';

import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import Cookie from 'js-cookie';
import { useDispatch } from 'react-redux';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faEdit,
  faChevronUp,
  faChevronDown,
  faVideo,
  faVideoSlash,
  faLock,
  faExclamationCircle,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  faSearch,
  faTrashAlt,
  faKey,
  faCheck,
  faCalendarAlt,
  faPoundSign,
  faSignInAlt,
  faSignOutAlt,
  faEnvelope,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

import SideBar from './shared/components/nav/sidebar';
import Dashboard from './dashboard/pages/dashboard';
import Stats from './stats/pages/Stats';
import Events from './event/pages/Event';
import Visitors from './visitors/pages/Visitors';
import Agenda from './agenda/pages/Agenda';
import Announce from './Announcments/pages/Announce';
import QASessions from './qAndA/pages/qAndA';
import Polls from './polls/pages/polls';
import Settings from './Settings/pages/Settings';

import Auth from './auth/pages/Auth';
import Forgot from './auth/pages/ForgotPassword';
import Reset from './auth/pages/ResetPassword';
import { useAuth } from './shared/hooks/auth-hook';
import { AuthContext } from './shared/context/auth-context';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';

import './App.css';


library.add(
  faEdit,
  faChevronUp,
  faChevronDown,
  faVideo,
  faVideoSlash,
  faLock,
  faExclamationCircle,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  fab,
  faTrashAlt,
  faKey,
  faCheck,
  faSignOutAlt,
  faCalendarAlt,
  faSearch,
  faEnvelope,
  faPoundSign,
  faSignInAlt,
  faExclamationTriangle
);

function App() {

  const dispatch = useDispatch();

  const {
    userId,
    userFirstName,
    userLastName,
    userProfileImageUrl,
    email,
    token,
    login,
    logout,
  } = useAuth();

  const eventId = Cookie.get('evid');
  if (eventId) {
    //console.log(eventId);
    dispatch({type: 'updateEventId', id: eventId});
  }

  let routes;

  if (!token) {
    routes = (
      <Switch>
        <Route path="/auth" exact>
          <Auth />
        </Route>
        <Route path="/forgot" exact>
          <Forgot />
        </Route>
        <Route path="/reset/:token">
          <Reset />
        </Route>
        <Route path="*" >
          <Auth />
        </Route>
      </Switch>
    );
  } else {
    routes = (
      <Fragment>
        <SocketContext.Provider value={socket}>
          <SideBar />
          <Switch>
          <Route path="/" exact>
              <Events />
            </Route>
          <Route path="/dashboard" exact>
              <Dashboard />
            </Route>
            <Route path="/agenda" exact>
              <Agenda />
            </Route>
            <Route path="/announcements" exact>
              <Announce />
            </Route>
            <Route path="/qa-sessions" exact>
              <QASessions />
            </Route>
            <Route path="/polls" exact>
              <Polls />
            </Route>
            <Route path="/attendees" exact>
              <Visitors />
            </Route>
            <Route path="/stats" exact>
              <Stats />
            </Route>
            <Route path="/settings" exact>
              <Settings />
            </Route>
            <Route path="*" >
              <Events />
            </Route>
          </Switch>
        </SocketContext.Provider>
      </Fragment>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        email: email,
        userProfileImageUrl : userProfileImageUrl,
        userFirstName: userFirstName,
        userLastName: userLastName,
        login: login,
        logout: logout,
      }}
    >
      <Router>
       
        <main>
          <Suspense 
            fallback={
              <div className="center">
                <LoadingSpinner />
              </div>
            }
          >
            {routes}
          </Suspense>
        </main>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;



