import React, { useState, useEffect, useContext } from 'react';
import { SocketContext } from '../../shared/context/socket';
import { AnimatePresence } from 'framer-motion';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import ModerationItem from './ModerationItem';
import ToggleQA from './ToggleQA';
import Notice from './Notice';
import Spinner from '../../shared/components/UIElements/LoadingSpinner';

import styles from './Moderation.module.css';

const Moderation = ({ session, updateSessionStatus }) => {
  const { REACT_APP_API_URL } = process.env;
  const { isLoading, sendRequest, error, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const socket = useContext(SocketContext);
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState();
  const [newAnswer, setNewAnswer] = useState();
  const [sessionState, setSessionState] = useState(false);

  useEffect(() => {
    const fetchQuestions = async session => {
      try {
        const responseData = await sendRequest(
          `${REACT_APP_API_URL}/api/qa/qa-session/${session.id}`,
          'POST',
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );

        if (responseData.session) {
          setSessionState(responseData.session.live);
          localStorage.setItem(
            'sessionData',
            JSON.stringify({ sid: responseData.session.id })
          );
          const sorted = responseData.session.qaQuestions.sort(compare);
          setQuestions(() => [...sorted]);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchQuestions(session);
  }, []);

  // listen for new questions on WS
  useEffect(() => {
    socket.on('Q&A', dataFromServer => {
      updateQuestions(dataFromServer);
    });
    return () => {
      socket.off('Q&A');
    }
  }, [socket]);

  useEffect(() => {
    newAnswer && questions && addAnswer(newAnswer);
  }, [newAnswer]);

  useEffect(() => {
    newQuestion && addNewQuestion(newQuestion);
  }, [newQuestion]);

  const updateQuestions = data => {
    const sid = getCurentSessionId();
    if (data && sid) {
      if (data.question) {
        if (data.question.sessionId === sid) {
          switch (data.action) {
            case 'new':
              setNewQuestion(data.question);
              break;

            case 'new answer':
              setNewAnswer(data.question);
              break;

            case 'selected':
              setNewAnswer(data.question);
              break;

            case 'answered':
              setNewAnswer(data.question);
              break;

           default:
              break;
          }
        }
      }
      if (data.action === 'session toggle') {
        const sid = getCurentSessionId();
        if (data.sessionId === sid) {
          toggleSessionState(data.isLive);
        }
      }
    }
  };

  const addNewQuestion = newQuestionData => {
    const sid = getCurentSessionId();
    if (sid === newQuestionData.sessionId) {
      const duplicate = questions.find(q => q.id === newQuestionData.id);
      if (!duplicate) {
        setQuestions(currentQs => [newQuestionData, ...currentQs]);
      }
    }
  };

  const addAnswer = questionData => {
    let currentStateArry = questions;
    const question = currentStateArry.find(q => {
      return q.id === questionData.id;
    });

    questionData.answered && (question.answered = questionData.answered);
    questionData.answeredAt && (question.answeredAt = questionData.answeredAt);
    questionData.selected && (question.selected = questionData.selected);
    questionData.selectedAt && (question.selectedAt = questionData.selectedAt);

    question.qaAnswers = questionData.qaAnswers;
    setQuestions([...currentStateArry]);
    console.log(currentStateArry);
  };

  function compare(a, b) {
    const ADate = a.createdAt;
    const BDate = b.createdAt;

    let comparison = 0;
    if (ADate < BDate) {
      comparison = 1;
    } else if (ADate > BDate) {
      comparison = -1;
    }
    return comparison;
  }

  const toggleSessionState = isLive => {
    setSessionState(isLive);
  };

  const getCurentSessionId = () => {
    const data = localStorage.getItem('sessionData');

    if (data) {
      return JSON.parse(data).sid;
    }
  };

  return (
    <div className={styles.sessionsWrapper}>
      <ErrorModal error={error} onClear={clearError} />
      {session && (
        <div className={styles.sessionsWrapperHeader}>
          <h1>{`Moderating ${session.name}`}</h1>
          <ToggleQA
            sessionId={session.id}
            isLive={sessionState}
            current={toggleSessionState}
          />
        </div>
      )}
      {isLoading ? (
        <div className="center">
          <Spinner />
        </div>
      ) : (
        <div className={styles.sessionsWrapperBody}>
          {questions.length === 0 && <Notice isLive={sessionState} />}
          <AnimatePresence>
            {questions &&
              questions.map(q => <ModerationItem key={q.id} question={q} />)}
          </AnimatePresence>
        </div>
      )}
    </div>
  );
};

export default Moderation;
