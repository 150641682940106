import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

import CreatePollForm from '../../shared/components/Forms/CreatePollForm';
import PollList from '../components/PollList';
import Header from '../../shared/components/header/Header';
import SubMenu from '../../shared/components/nav/subMenu';
import ContentContainer from '../../shared/components/UIElements/ContentContainer';

import './polls.css';

const subMenu = [
  {
    id: 0,
    title: 'Poll List'
  },
  {
    id: 1,
    title: 'Create Poll'
  }
]


const Polls = props => {

  const evid = useSelector(state => state.eid);
  const [showPollList, setShowPollList] = useState(true);
  const [showAddPoll, setShowAddPoll] = useState(false);
  const [eventListData, setEventListData] = useState();


  const subMenuStateHandler = (id) => {
    console.log(id);
    
    switch (id) {
      case 0:
        setShowAddPoll(false);
        setShowPollList(true);        
        break;
      case 1:
        setShowAddPoll(true);
        setShowPollList(false);        
        break;
    
      default:
        break;
    }
  }
 

  return (
    <Fragment>
      <div className="page__wrapper">
        <Header title={'Polls'} />
        <SubMenu items={subMenu} click={subMenuStateHandler} />
        <div className="page-content clear-header pc-stats">
          <div className="polls-content-wrapper">
           
          {showAddPoll && <ContentContainer title='Create New Poll'>
            <CreatePollForm eventId={evid} success={()=> subMenuStateHandler(0)}/>
          </ContentContainer>}

         {showPollList && 
          <PollList eventId={evid} created={()=> subMenuStateHandler(0)} />
          }

          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Polls;
