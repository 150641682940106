import React, {useContext, Fragment, useState, useEffect} from 'react';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import styles from './ToggleQA.module.css';
import ContentContainer from '../../shared/components/UIElements/ContentContainer';
import ESCheckbox from '../../shared/components/FormElements/ESCheckbox';

const ToggleQA = ({sessionId, isLive, current}) => {

    const { REACT_APP_API_URL } = process.env;
    const auth = useContext(AuthContext);
    const { error, sendRequest, clearError } = useHttpClient();
    const [qaState, setQaState] = useState(false);

    useEffect(() => {
        if (qaState !== isLive) {
            console.log(qaState);
            setQaState(isLive)
        }
       
    }, [isLive])

    useEffect(() => {
        const checkStatus = async () => {
            try {
                const response = await sendRequest(
                  `${REACT_APP_API_URL}/api/qa/session-status/${sessionId}`,
                  'POST',
                  {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + auth.token,
                  }
                );

                if (response.isLive) {
                    setQaState(response.isLive);
                }
               
              } catch (err) {
                console.log(err);
              }
        }
        checkStatus()
    }, [])

    const toggleQA = async (state) => {
        console.log('session toggled: ' + state);
       
        try {
            await sendRequest(
              `${REACT_APP_API_URL}/api/qa/toggle-session/${sessionId}`,
              'PATCH',
              JSON.stringify({
                isLive: state
              }),
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token,
              }
            );
          } catch (err) {
            console.log(err);
          }
    }

    return ( 
        <Fragment>
            <ErrorModal error={error} onClear={clearError} />
            <div id={styles.qaToggleWrapper}>
                <ContentContainer classNameCC={styles.contentContainer} title='Q&A Session Control'>
                    <div className={styles.toggleContainer}>
                       <ESCheckbox id={sessionId} state={toggleQA} toggle={qaState} checkType={false}/>
                    </div>
                </ContentContainer>
            </div>
        </Fragment>
    )
}

export default ToggleQA;