import React, { useEffect, useState } from 'react';
import {Bar} from 'react-chartjs-2';

import './PollChart.css';
  

const PollChart = ({poll}) => {

    const [labels, setLabels] = useState([]);
    const [values, setValues] = useState([]);

    useEffect(() => {
        if (poll) {
            if (poll.pollElements ) {
              
                const sortedOptions = poll.pollElements.sort((a,b) => {
                    if (a.label > b.label ) {
                        return 1
                    } else if (a.label < b.label) {
                        return -1
                    }
                })

                var labelsArr = []
                var valuesArr = []

                sortedOptions.map(element => {
                    labelsArr.push(element.option) 
                    valuesArr.push(element.value)
                })

                setLabels(labelsArr)
                setValues(valuesArr)
              }
        }
        
      }, [poll]);

    return (
        <div className='poll-chart-container'>
         
            <Bar 
                data={{
                    labels: labels,
                    datasets: [
                        {
                        label: poll.question,
                        data: values,
                        backgroundColor: '#C21807',
                        borderColor: '#C21807',
                        borderWidth: 0
                        }
                    ]
                }}
                redraw={false}
                options= {{
                    maintainAspectRatio : false,
                    plugins: {
                        tooltip: {
                            backgroundColor: '#333',
                            displayColors: false
                            
                        }
                    }
                }}
                
            />
        </div>
    )
}

export default PollChart;

