import React, { useEffect, useState, useContext } from 'react'
import {Doughnut} from 'react-chartjs-2';

import { AuthContext } from '../../../shared/context/auth-context';
import { useHttpClient } from '../../../shared/hooks/http-hook';

import './PieChart.css';

const PieChart = () => {

  const { REACT_APP_API_URL } = process.env;

    const { sendRequest } = useHttpClient();
    const auth = useContext(AuthContext);
    
    const [rawStats, setRawStats] = useState([]);
    const [filteredStats, setFilteredStats] = useState([]);
    const [browserType, setBrowserTypes] = useState([]);
    const [browserTypeCount, setBrowserTypeCount] = useState([]);

    function removeDuplicates(originalArray, arrayKeys) {
        const filtered = originalArray.filter(
          (
            s => o =>
              (k => !s.has(k) && s.add(k))(arrayKeys.map(k => o[k]).join('|'))
          )(new Set())
        );
    
        console.log(filtered);
        return filtered;
      }

      useEffect(() => {
        const fetchStats = async () => {
          try {
            const responseData = await sendRequest(
              `${REACT_APP_API_URL}/api/stats/browser`,
              'POST',
              {
                'Content-Type': 'application/json',
                Authorization: auth.token,
              }
            );      
            if (responseData.stats ) {
              if (responseData.stats.length !== rawStats.length){
                setRawStats(responseData.stats);
                setFilteredStats(removeDuplicates(responseData.stats, ['userId', 'browserName']));
              }
            }
           
          } catch (err) {
            console.log(err);
          }
        };
        fetchStats();
      }, []);

      useEffect(() => {

        var browserList = []
     
         filteredStats.map(item => (  
            browserList.push(item.browserName)
         ))
  
         const counts = {};
         browserList.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
        
         const deviceNames = Object.keys(counts);
         const deviceNumbers = Object.values(counts);
         setBrowserTypes(deviceNames);
         setBrowserTypeCount(deviceNumbers)
     
       }, [filteredStats]);
    

    return (
        <div className='pie-chart-container'>
         {rawStats.length === 0 ? <div className='no-browser-data-notice'><h1 className='no-browser-data-title'>No browser data to show yet</h1><p className='no-browser-data-text'>Data will show when attendees start viewing your content.</p></div> :
            <Doughnut 
                data={{
                    labels: browserType,
                    datasets: [{
                        data: browserTypeCount,
                        backgroundColor: [
                          '#E07066',
                      '#D75245',
                      '#CD3525',
                      '#C21807',
                      '#B41404',
                      '#A51102',
                      '#960E00',
                        ],
                        borderColor: 
                            'white',
                        borderWidth: 2
                    }]
                }}
                
                
                options={{
                    maintainAspectRatio : false
                }}
            />
         }
        </div>
    )
}

export default PieChart;