import React from 'react';

import Button from '../../shared/components/UIElements/Buttons/Button';
import styles from './GlobalActions.module.css';

const GlobalActions = () => {

    const deleteEvent = eventId => {
        
    }

    return (
        <div className={styles.actions}>
            <Button title='Delete Event' className={`button-outline-danger`}  onClick={deleteEvent}/>
        </div>
    )
}

export default GlobalActions;