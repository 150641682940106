import React, {useState, useEffect, useContext} from 'react'

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import LoadingDots from '../../shared/components/UIElements/LoadingDots';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import CSVExportQuestions from '../../shared/components/CSVExport/CSVExportQuestions';

import styles from './QAList.module.css';
import CSVExportAnswers from '../../shared/components/CSVExport/CSVExportAnswers';


const QAList = ({eventId}) => {

    const { REACT_APP_API_URL } = process.env;
    const { isLoading, sendRequest, error, clearError } = useHttpClient();
    const auth = useContext(AuthContext);
    const [qaSessionList, setQASessionList] = useState([]);

    useEffect(() => {
        const fetchSessions = async () => {
         
            try {
                    const responseData = await sendRequest(
                        `${REACT_APP_API_URL}/api/qa/qa-sessions`,
                        'POST',
                        {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + auth.token,
                        }
                    );
                    if (responseData.sessions) {
                        setQASessionList(responseData.sessions);
                        console.log(responseData.sessions);
                    }
                
                } catch (err) {
                console.log(err);
                }
              
        }
        fetchSessions()
    }, [])

    return (
        <div className={styles.mainWrapper}>
        <ErrorModal error={error} clearError={clearError} />
        {isLoading && <LoadingDots className={styles.dots}/>}
            {qaSessionList.length > 0 && qaSessionList.map(session => (
                <div key={session.id} className={styles.sessionItem}>
                    <div className={styles.title}>
                        <h1>{session.name}</h1>
                        <div className={styles.actions}>
                            <CSVExportQuestions sessionId={session.id} sessionName={session.name} />
                            <CSVExportAnswers sessionId={session.id} sessionName={session.name} />
                        </div>
                    </div>
                    
                </div>
            ))}
        </div>
    )
}

export default QAList;