import React, {Fragment, useState, useEffect, useContext} from 'react';


import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';

import styles from './DeleteSession.module.css';
import DeleteSessionList from './DeleteSessionList';

const DeleteSession = ({udateSessions}) => {

    const { REACT_APP_API_URL } = process.env;
    const { isLoading, sendRequest, error, clearError } = useHttpClient();
    const auth = useContext(AuthContext);

    const deleteSessionHandler = async id => {

        try {
            const response = await sendRequest(
            `${REACT_APP_API_URL}/api/qa/session/${id}`,
            'DELETE',
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token,
            }
          );    
          
          if (response) {
            udateSessions()
          }
         
            
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Fragment>
            <ErrorModal error={error} onClear={clearError} />
            {isLoading && <div className='center'><LoadingSpinner /></div>}
            {!isLoading &&
                <section id={styles.deleteWrapper}>
                    <DeleteSessionList chosenId={deleteSessionHandler} />
                </section>
            }
        </Fragment>
    )
}

export default DeleteSession;