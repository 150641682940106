import React, { Fragment } from 'react';
import BarChart from '../../shared/components/Charts/BarChart';
import PieChart from '../../shared/components/Charts/PieChart';
import DeviceDonut from '../../shared/components/Charts/DeviceDonut';


import Header from '../../shared/components/header/Header';

import './Stats.css';
import WolrdMapChart from '../../shared/components/Charts/WorldMapChart';
import CSVExportAttendance from '../../shared/components/CSVExport/CSVExportAttendance';

const Stats = props => {


  return (
    <Fragment>
      <div className="page__wrapper">
        <Header title={'Stats'} />
        <div className="page-content clear-header pc-stats">
          <div className="content-wrapper">
            <div className="stat-container">
             <p>will build some sort of graph to show attendee login over time period</p>
            </div>

           
            <div className="stat-container">
              <h1>GEO Location</h1>
              <WolrdMapChart />
              
            </div>

            <div className="stat-container">
              <h1>Browser usage</h1>
              <PieChart />
            </div>

            <div className="stat-container">
              <h1>Device usage</h1>
              <DeviceDonut />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Stats;
