import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Header from '../../shared/components/header/Header';
import StreamPreview from '../../shared/components/Video/StreamPreview';
import WhosOnline from '../../shared/components/WhosOnline/WhosOnline';

import './dashboard.css';
import styles from './dashboard.module.css';

const Dashboard = props => {

  const evid = useSelector(state => state.eid);
    //console.log(evid);

  return (
    <Fragment>
      <div className={"page__wrapper"}>
        <Header title={'Dashboard'} />
        <div className="page-content  pc-stats">
          <div className={styles.pageWrapper}>
          <StreamPreview />
          <WhosOnline />
           

           
            

          

            
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Dashboard;
