import React, { useEffect, useState, useContext } from 'react';

import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';


import Input from '../FormElements/Input';
import Select from '../FormElements/Select';
import Button from '../UIElements/Buttons/Button';
import LoadingDots from '../UIElements/LoadingDots';
import ErrorModal from '../UIElements/Modal/ErrorModal';

import styles from './CreateSessionForm.module.css';

const CreateSessionForm = ({success}) => {

    const { REACT_APP_API_URL } = process.env;
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    


    const onSubmit = async (event) => {
     event.preventDefault()
      
        clearError();
    
        try {
          const response = await sendRequest(
            `${REACT_APP_API_URL}/api/qa/create-session`,
            'POST',
            JSON.stringify({
              name: event.target.name.value,
              
            }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token,
            }
          );
          console.log(response);
          success()
        } catch (err) {
          console.log(err);
        }
      };

      return (
          <div className={styles.createSessionFormWrapper}>
            <ErrorModal error={error} onClear={clearError} />
            {isLoading && <LoadingDots className={styles.loadingDots} />}
            {!isLoading && 
                <form className={styles.form} onSubmit={onSubmit} >
                <Input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="required"
                    groupFront="Q&A Session Name:"
                />
                <div className='create-event-actions'>
                    <Button title='create' className={`button-secondary ${styles.createButton}`} />
                </div>
                </form>
            }
          </div>
      )
}

export default CreateSessionForm;