import React, {useEffect, useState, useContext  } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';
import { SocketContext } from '../../context/socket';

import LoadingSpinner from '../../components/UIElements/LoadingSpinner';
import ErrorModal from '../../components/UIElements/Modal/ErrorModal';

import styles from './WhosOnline.module.css';
import ContentContainer from '../UIElements/ContentContainer';
import WOList from './WOList';

const WhosOnline = () => {

    const { REACT_APP_API_URL } = process.env;
    const history = useHistory();
    const { isLoading, sendRequest, error, clearError } = useHttpClient();
    const auth = useContext(AuthContext);
    const socket = useContext(SocketContext);
    const evid = useSelector(state => state.eid);
    const [list, setList] = useState([]);

    const [newConnection, setNewConnection] = useState();
    const [loggedOutItem, setLoggedOutItem] = useState();
    const [dissconnectedItem, setDisconnectedItem] = useState();

     // listen for new questions on WS
     useEffect(() => {
        socket.on('CONN', data => {
            console.log(data);
            updateConnections(data);
        });
        return () => {
            socket.off('CONN');
          }

    }, [socket]);


    useEffect(() => {
        const fetchList = async () => {
          try {
            const responseData = await sendRequest(
              `${REACT_APP_API_URL}/api/conn/`,
              'POST',
              {
                'Content-Type': 'application/json',
                Authorization: auth.token,
              }
            );
            console.log(responseData);
            setList(responseData);
          } catch (err) {
            console.log(err);
          }
        };
      
        if (auth.isLoggedIn) {
          evid && fetchList();
        } else {
          history.push("/auth");
        }
      }, [])


      const updateConnections = data => {
          if (data) {
            switch (data.action) {
                case 'Connected':
                  setNewConnection(data);
                  break;
    
                case 'loggedOut':
                  setLoggedOutItem(data.socketId);
                  break;
    
                case 'disconnected':
                  setDisconnectedItem(data.socketId);
                  break;
    
               default:
                  break;
              }
          }
      }

      useEffect(() => {
          console.log(newConnection);
          addNewConnection(newConnection);
      }, [newConnection])

      useEffect(() => {
        console.log(loggedOutItem);
    }, [loggedOutItem])

    useEffect(() => {
        console.log(dissconnectedItem);
        removeConnection(dissconnectedItem);
    }, [dissconnectedItem])
   
    const addNewConnection = data => {
        if (data) {
        const newConn = {
            createdAt: data.data.createdAt,
            id: data.data.id,
            ip: data.data.ip,
            socketId: data.data.socketId,
            ticketNo: data.data.ticketNo,
            updatedAt: data.data.updatedAt,
            user: {
                id: data.user.id, 
                email: data.user.email, 
                name:data.user.name, 
                organization: data.user.organization, 
                password: data.user.password,
                approved: data.user.approved,
                createdAt: data.user.createdAt, 
                lastLoggedIn: data.user.lastLoggedIn,
                locked: data.user.locked,
                logginCount: data.user.logginCount,
                registered: data.user.registered,
                suspended: data.user.suspended,
                ticketNo: data.user.ticketNo,
                updatedAt: data.user.updatedAt
            },
            userId: data.data.userId
        }

        console.log(data);
        setList(currentList => [newConn, ...currentList]);
        }
    }

    const updateloggedOutItem = socketId => {
        
    }

    const removeConnection = socketId => {
        if (socketId) {
            let connections = list.filter(conn => conn.socketId !== socketId);
            setList(()=> [...connections]);
        }
    }


    return (
        <section id={styles.WOL}>
            <ContentContainer title='Whos Online'>
            {list.length > 0 && <WOList list={list} />}
            {list.length === 0 && 
                <div className={styles.noUsers}>
                    <h1>The show is empty!</h1>
                </div>
            }

            </ContentContainer>
        </section>
    )
}

export default WhosOnline;