import React, {useEffect, useState, Fragment} from 'react';

import styles from './ESCheckbox.module.css'

const ESCheckbox = ({ id, checkType, toggle, state, style }) => {

const [toggleState, setToggleState] = useState()
const [checkboxId, setCheckboxId] = useState()



// useEffect(() => {
    
//     if (id !== checkboxId && toggle !== toggleState) {
//         setToggleState(toggle);
//         setCheckboxId(id)
//     }
   
// }, [id, toggle])

const changeState = event => {
    console.log(event.target.checked);
     //setToggleState(event.target.checked)
     state(event.target.checked)
    }

  return (
    <Fragment>
      {checkType ? (
        <div className={styles.checkbox} style={style}>
          <label className={styles.checkbox__container}>
            <input id={id} className={styles.checkbox__toggle} type="checkbox" checked={toggle} onChange={changeState}></input>
            <span className={styles.checkbox__checker}></span>
            <span className={styles.checkbox__cross}></span>
            <span className={styles.checkbox__ok}></span>
            <svg
              className={styles.checkbox__bg}
              space="preserve"
              style={{enableBackground: 'new 0 0 110 43.76'}}
              version="1.1"
              viewBox="0 0 110 43.76"
            >
              <path
                className={styles.shape}
                d="M88.256,43.76c12.188,0,21.88-9.796,21.88-21.88S100.247,0,88.256,0c-15.745,0-20.67,12.281-33.257,12.281,S38.16,0,21.731,0C9.622,0-0.149,9.796-0.149,21.88s9.672,21.88,21.88,21.88c17.519,0,20.67-13.384,33.263-13.384,S72.784,43.76,88.256,43.76z"
              ></path>
            </svg>
          </label>
        </div>
      ) : (
        <div className={styles.checkbox} style={style}>
          <label className={styles.checkbox__container}>
            <input className={styles.checkbox__toggle} type="checkbox" checked={toggle} onChange={changeState}></input>
            <span className={styles.checkbox__checker}></span>
            <span className={styles.checkbox__txtLeft}>LIVE</span>
            <span className={styles.checkbox__txtRight}>OFF</span>
            <svg
              className={styles.checkbox__bg}
              space="preserve"
              style={{enableBackground:'new 0 0 110 43.76'}}
              version="1.1"
              viewBox="0 0 110 43.76"
            >
              <path
                className={styles.shape}
                d="M88.256,43.76c12.188,0,21.88-9.796,21.88-21.88S100.247,0,88.256,0c-15.745,0-20.67,12.281-33.257,12.281,S38.16,0,21.731,0C9.622,0-0.149,9.796-0.149,21.88s9.672,21.88,21.88,21.88c17.519,0,20.67-13.384,33.263-13.384,S72.784,43.76,88.256,43.76z"
              ></path>
            </svg>
          </label>
        </div>
      )}
    </Fragment>
  );
};

export default ESCheckbox;
