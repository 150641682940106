import React, {  useContext, useState} from 'react';
import { faLock, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import ESCheckbox from '../../shared/components/FormElements/ESCheckbox';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';

import styles from './RemoveItem.module.css';
import { Fragment } from 'react';

const RemoveItem = ({ attendee, update, expemptId, addId }) => {


  const [toBeDeleted, setToBeDeleted] = useState(true);

  const { REACT_APP_API_URL } = process.env;
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const deleteAttendee = async id => {

    try {
        const response = await sendRequest(
          `${REACT_APP_API_URL}/api/user/remove-user/${id}`,
          'DELETE',
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        console.log(response);
        update()
      } catch (err) {
        console.log(err);
      }
}

  const deleteState = state => {
    console.log(state);
    setToBeDeleted(state);
    state ? addId(attendee.id) : expemptId(attendee.id);
   
  };

  return (
      <Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>)}
    <tr>
      <td className={styles.times}>
        {attendee.locked ? (
          <span className={styles.lock}>
            <FontAwesomeIcon icon={faLock} />
          </span>
        ) : (
          <ESCheckbox
            id={attendee.id}
            style={{ transform: 'scale(-0.6) rotate(180deg)' }}
            state={deleteState}
            toggle={toBeDeleted}
            checkType={true}
          />
        )}
      </td>
      <td>{attendee.name}</td>
      <td>{attendee.email}</td>
      <td>{attendee.organization}</td>
      <td>
        {!attendee.locked && (
          <button
            onClick={() => deleteAttendee(attendee.id)}
            className={styles.trash}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        )}
      </td>
    </tr>
    </Fragment>
  );
};

export default RemoveItem;
