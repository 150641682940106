import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState, useEffect, useContext} from 'react';
import LoadingDots from '../../shared/components/UIElements/LoadingDots';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import styles from './DeleteLock.module.css';

const DeleteLock = ({id, update, isLocked}) => {

    const { REACT_APP_API_URL } = process.env;
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [toggle, setToggle] = useState()

    useEffect(() => {
       isLocked && setToggle(isLocked)
    }, [])
    const toggleLock = async lockState => {

        console.log(lockState);
        setToggle(lockState);

        try {
            const response = await sendRequest(
              `${REACT_APP_API_URL}/api/user/safe-lock/${id}`,
              'PATCH',
              JSON.stringify({
                lock:lockState,
              }),
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token,
              }
            );
            console.log(response);
            update()
            
          } catch (err) {
            console.log(err);
          }

    }

    return (
        <div className={styles.deleteLockWrapper} onClick={()=>toggleLock(toggle ? false : true)}>
        <ErrorModal error={error} onClear={clearError} />
        {isLoading && <LoadingDots className={styles.dots} />}
        {!isLoading && !toggle &&
            <div className={styles.innerContent}>
                <div className={styles.lockToggle}><FontAwesomeIcon icon={faLockOpen}/></div>
                <span className={styles.text}>Delete Lock</span>
            </div>
        }
        {!isLoading && toggle &&
        <div className={styles.innerContent}>
            <div className={styles.lockToggle}><FontAwesomeIcon icon={faLock}/></div>
            <span className={styles.text}>Delete Lock</span>
        </div>
           
        }
        </div>
    )
}

export default DeleteLock;