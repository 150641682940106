import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';

import Input from '../FormElements/Input';
import Select from '../FormElements/Select';
import Button from '../UIElements/Buttons/Button';
import LoadingSpinner from '../UIElements/LoadingSpinner';
import ErrorModal from '../UIElements/Modal/ErrorModal';

import styles from './EditEventForm.module.css';
const EditEventForm = () => {

    const { REACT_APP_API_URL } = process.env;
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [event, setEvent] = useState();
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [date, setDate] = useState('');

    const evid = useSelector(state => state.eid);
    //console.log(evid);
    const dateFormat = "YYYY-MM-DD"

    useEffect(() => {
     const fetchEvent = async () => {
      try {
        const response = await sendRequest(
          `${REACT_APP_API_URL}/api/event/event`,
          'POST',
          JSON.stringify({
            eid: evid
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        console.log(response);
        if (response.event) {
          if (response.event.length > 0) {
            setEvent(response.event[0]);
            setTitle(response.event[0].title);
            setSubTitle(response.event[0].subTitle);
            setDate(moment(response.event[0].date).format(dateFormat));
          }
        }
        
      } catch (err) {
        console.log(err);
      }
     }
     evid && fetchEvent()
    }, [evid])




    const onSubmit = async event => {
     event.preventDefault()
      
        clearError();
    
        try {
          const response = await sendRequest(
            `${REACT_APP_API_URL}/api/event/update`,
            'PATCH',
            JSON.stringify({
              eid:evid,
              title: event.target.title.value,
              subTitle: event.target.subTitle.value,
              date: event.target.date.value
            }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token,
            }
          );
          console.log(response);
          
        } catch (err) {
          console.log(err);
        }
      };

      const onChangeHandlerTitle = event => {
        setTitle(event.target.value)
      }

      const onChangeHandlerSubTitle = event => {
        setSubTitle(event.target.value)
      }

      const onChangeHandlerDate = event => {
        setDate(event.target.value)
      }

      return (
          <div className={styles.createEventFormWrapper}>
            <ErrorModal error={error} onClear={clearError} />
            {isLoading && <LoadingSpinner />}
            {!isLoading && event && 
                <form onSubmit={onSubmit}>
                <Input
                    id="title"
                    name="title"
                    type="text"
                    value={title}
                    onChange={onChangeHandlerTitle}
                    groupFront="Event Title:"
                />
                <Input
                    id="subTitle"
                    name="subTitle"
                    type="text"
                    value={subTitle}
                    onChange={onChangeHandlerSubTitle}
                    groupFront="Event Sub Title:"
                />
                <Input
                    id="date"
                    name="date"
                    type="date"
                    value={date}
                    onChange={onChangeHandlerDate}
                    groupFront="Event Date:"
                />
                <div className={styles.createEventActions}>
                    <Button title='save' className='button-secondary' />
                </div>
                </form>
            }
          </div>
      )
}

export default EditEventForm;