import React, {useContext, Fragment} from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import styles from './AgendaList.module.css';



const AgendaList = ({ agenda, update }) => {

    const { REACT_APP_API_URL } = process.env;
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    const deleteItem = async id => {

        try {
            const response = await sendRequest(
              `${REACT_APP_API_URL}/api/agenda/delete/${id}`,
              'DELETE',
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token,
              }
            );
            console.log(response);
            update()
          } catch (err) {
            console.log(err);
          }
    }

    return (
      <Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading ? <LoadingSpinner /> : 
        <table className={styles.table}>
          <colgroup>
            <col span="1" style={{width: "10rem"}}/>
            <col span="1" style={{width: "10rem"}}/>
            <col span="1" style={{width: "25%"}}/>
            <col span="1" style={{width: "20%"}}/>
            <col span="1" style={{width: "40%"}}/>
          </colgroup>
          <thead className={styles.header}>
            <tr>
              <th>Start</th>
              <th>End</th>
              <th>Title</th>
              <th>Sub Title</th>
              <th>description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {agenda && agenda.sort(compare).map(item =>(
            <tr key={item.id}>
              <td className={styles.times}>{item.startTime.slice(0, -3)}</td>
              <td className={styles.times}>{item.endTime.slice(0, -3)}</td>
              <td>{item.title}</td>
              <td>{item.subTitle}</td>
              <td className={styles.desc}>{item.description}</td>
              <td><button onClick={()=>deleteItem(item.id)} className={styles.trash}><FontAwesomeIcon icon={faTrashAlt}/></button></td>
            </tr>
          ))}
          </tbody>
          </table>
        }
        </Fragment>
    );
};

function compare(a, b) {
    const ADate = a.startTime;
    const BDate = b.startTime;

    let comparison = 0;
    if (ADate > BDate) {
      comparison = 1;
    } else if (ADate < BDate) {
      comparison = -1;
    }
    return comparison;
  }

export default AgendaList;