import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import Header from '../../shared/components/header/Header';
import SubMenu from '../../shared/components/nav/subMenu';
import AddVisitor from '../components/AddVisitor';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';

import VisitorList from '../components/VisitorList';
import RemoveVisitors from '../components/RemoveVisitors';
import './Visitors.css';


const subMenu = [
  {
    id: 0,
    title: 'list'
  },
  {
    id: 1,
    title: 'add attendee'
  },
  {
    id: 2,
    title: 'remove attendees'
  },
  
]

const Attendees = props => {

  const { REACT_APP_API_URL } = process.env;
  const history = useHistory();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const evid = useSelector(state => state.eid);
  //console.log(evid);
  
  const [showVisitorList, setShowVisitorList] = useState(true);
  const [showAddVisitor, setShowAddVisitor] = useState(false);
  const [showRemoveVisitor, setShowRemoveVisitor] = useState(false);
  const [updateList, setUpdateList] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    const fetchVisitors = async () => {
      try {
        const responseData = await sendRequest(
          `${REACT_APP_API_URL}/api/user/`,
          'POST',
          {
            'Content-Type': 'application/json',
            Authorization: auth.token,
          }
        );

        setList(responseData.visitors);
        console.log(responseData.visitors);
      } catch (err) {
        console.log(err);
      }
    };
  
    if (auth.isLoggedIn) {
      evid && fetchVisitors();
    } else {
      history.push("/auth");
    }
  }, [updateList])
  
  const selectVisitorHandler = id => {
    
    console.log(id);
   
  }

  const subMenuStateHandler = (id) => {
    console.log(id);
    
    switch (id) {
      case 0:
        setShowAddVisitor(false);
        setShowVisitorList(true);
        setShowRemoveVisitor(false);        
        break;
      case 1:
        setShowAddVisitor(true);
        setShowVisitorList(false);  
        setShowRemoveVisitor(false);       
        break;
      
        case 2:
        setShowAddVisitor(false);
        setShowVisitorList(false);
        setShowRemoveVisitor(true);        
        break;
    
      default:
        break;
    }
  }
  
  return (
    <div className="page__wrapper">
    <ErrorModal error={error} onClear={clearError} />
    <Header title={'Attendees'} />
    <SubMenu items={subMenu} click={subMenuStateHandler} />
    <div className="page-content clear-header">
      {showVisitorList && <VisitorList  attendees={list} selectVisitorHandler={selectVisitorHandler} update={()=>setUpdateList(state=>!state)} eventId={evid}/>}
      {showAddVisitor && <AddVisitor />}
      {!isLoading && showRemoveVisitor && <RemoveVisitors attendees={list} update={()=>setUpdateList(state=>!state)} />}
      {isLoading && <LoadingSpinner />}
    </div>
  </div>
  );
};

export default Attendees;
