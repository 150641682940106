import React, { useEffect, useState, useContext } from 'react';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import Button from '../../shared/components/UIElements/Buttons/Button';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import ContentContainer from '../../shared/components/UIElements/ContentContainer';
import PollListItem from './PollItem';

import './PollList.css';
import CreatePollForm from '../../shared/components/Forms/CreatePollForm';


const PollList = ({eventId, created}) => {

    const { REACT_APP_API_URL } = process.env;
    const { isLoading, sendRequest, error, clearError } = useHttpClient();
    const auth = useContext(AuthContext);
    const [polls, setPolls] = useState([]);
    const [update, setUpdate] = useState(true)

    const [op, setOp] = useState([]);

    useEffect(() => {
        const fetchPolls = async () => {
          try {
            const responseData = await sendRequest(
              `${REACT_APP_API_URL}/api/poll/event-polls`,
              'POST',
              JSON.stringify({
                eventId: eventId
              }),
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token,
              }
            );      
            if (responseData.polls ) {
              console.log(responseData.polls);
                setPolls(responseData.polls )
            }
           
          } catch (err) {
            console.log(err);
          }
        };
        fetchPolls();
      }, [update]);

      const updateList = () => {
        setUpdate(update ? false : true)
        created();
      }

    return (
        <section id='poll-list'>
            <ErrorModal error={error} onClear={clearError} />
            {isLoading && <div className='center'><LoadingSpinner /></div>}
            {polls.length > 0 ?
            <div className='poll-list-container'>
             
                    {polls.map(poll => (
                      <div key={poll.id}>
                        <PollListItem poll={poll} deleted={updateList} />
                      </div>
                        
                    ))}
                  
            </div>
            : 
            <div className='no-poll-list'>
            {!isLoading && 
                <ContentContainer title='Create A Poll'>
                    <div className='no-poll-textbox'>
                    <h1 className='no-poll-title'> No polls yet!</h1>
                    <p className='no-poll-text'>Lets get started!, create a new poll</p>
                      <CreatePollForm eventId={eventId} optionList={op} success={updateList}/>
                      
                    </div>
                
                </ContentContainer>
            }
            </div>
            }
        </section>
    )
}

export default PollList;