import React, {useState, useEffect, useContext} from 'react'

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import Button from '../../shared/components/UIElements/Buttons/Button';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import styles from './ModerationActions.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import LoadingDots from '../../shared/components/UIElements/LoadingDots';
import Modal from '../../shared/components/UIElements/Modal/Modal';
import QAReply from '../../shared/components/Forms/QAReply';


const ModerationActions = ({question}) => {

    const { REACT_APP_API_URL } = process.env;
    const { isLoading, sendRequest, error, clearError } = useHttpClient();
    const auth = useContext(AuthContext);
    const [isApproved, setIsApproved] = useState(question.approved);
    const [answered, setAnswered] = useState(question.answered);
    const [answeredAt, setAnsweredAt] = useState(question.answeredAt);
    const [selected, setSelected] = useState(question.selected);
    const [selectedAt, setSelectedAt] = useState(question.selectedAt);
    const [showReplyModal, setShowReplyModal] = useState(false);
   
    useEffect(() => {

        setAnswered(question.answered);
        setAnsweredAt(question.answeredAt);
        setSelected(question.selected);
        setSelectedAt(question.selectedAt);
    }, [question])

   
    const replyHandler = () => {
        setShowReplyModal(true)
    }

    const approveHandler = async () => {

        try {
            const responseData = await sendRequest(
              `${REACT_APP_API_URL}/api/qa/approve/${question.id}`,
              'PATCH',
                JSON.stringify({
                    isApproved: true,
                }),
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token,
              }
            );
        console.log(responseData.result);      
            if (responseData.result ) {
                setIsApproved(responseData.result.approved )
            }
           
          } catch (err) {
            console.log(err);
          }
    }

    const messageSuccessHandler = () => {
        setShowReplyModal(false);
    }


    return (
        <div id={styles.actionsWrapper}>
            <ErrorModal error={error} onClear={clearError} />
           {!isApproved ? <Button className={`button-green ${styles.button}`} title='Approve' onClick={approveHandler}/> :  <div className={styles.approveNotice}><span><FontAwesomeIcon icon={faCheck} /></span>Approved</div>}
         
            <Button className={`button-warning ${styles.button}`} title='reply' onClick={replyHandler}/>
            {isLoading && <div className={styles.loadingWrapper}><LoadingDots className={styles.loadingDots} /><span>Loading</span></div>}
           <Modal show={showReplyModal} hideButton>
               <QAReply success={messageSuccessHandler} cancel={()=>setShowReplyModal(false)} qid={question.id} />
           </Modal>
        </div>
    )
}

export default ModerationActions;