import React, {useState, useEffect, useContext, useRef} from 'react'
import {CSVLink} from 'react-csv';
import Input from '../FormElements/Input';

import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';
import LoadingDots from '../UIElements/LoadingDots';
import ErrorModal from '../UIElements/Modal/ErrorModal';
import styles from './CSVExportQuestions.module.css';
import Button from '../UIElements/Buttons/Button';

const headers = [
    {
        label: 'Date/Time', 
        key: 'createdAt'
    },
    {
        label: 'Question', 
        key: 'question'
    },
    {
        label: 'Answer', 
        key: 'answer'
    },
    {
        label: 'Author Name', 
        key: 'questionAurthorName'
    },
    {
        label: 'Author Email', 
        key: 'questionAurthorEmail'
    },
    {
        label: 'Staff Name', 
        key: 'answerAuthorName'
    },
    {
        label: 'Staff Email', 
        key: 'answerAuthorEmail'
    }
    
];

const CSVExportAnswers = ({sessionId, sessionName}) => {

    

    const { REACT_APP_API_URL } = process.env;
    const { isLoading, sendRequest, error, clearError } = useHttpClient();
    const auth = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [downloadData, setDownLoadData] = useState([]);
    const [endDate, setEndDate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [showGetLogs, setShowGetLogs] = useState(true);
    const csvLinkRef = useRef();

    const getDataHandler = async () => {

         
                try {
                    const responseData = await sendRequest(
                      `${REACT_APP_API_URL}/api/qa/questions/${sessionId}`,
                      'POST',
                    {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + auth.token,
                    }
                    );
                    console.log(responseData);
                    if (responseData.questions){
                        console.log(responseData.questions);
                        const rawData = responseData.questions;
                        let csvData = [];

                        for (let x=0; x < rawData.length; x++ ) {

                            const question = rawData[x];
                            if (question.qaAnswers.length > 0) {
                                const answers = question.qaAnswers;
                                for (let y=0; y < answers.length; y++) {
                                    const answer = answers[y];
                                    const newItem = {
                                        createdAt: answer.createdAt,
                                        question: question.question,
                                        answer: answer.answer,
                                        questionAurthorName: question.user.name || 'not found',
                                        questionAurthorEmail: question.user.email || 'not found',
                                        answerAuthorName: (answer.staff.firstName || '') + " " + (answer.staff.lastName || ''),
                                        answerAuthorEmail: answer.staff.email || 'not found'
                                    }
                                    csvData.push(newItem);
                                }
                            }
                        }

                        console.log(csvData);
                        setData(()=>[...csvData]);

                        csvLinkRef.current.link.click();
                        
                    }
                  } catch (err) {
                    console.log(err);
                  }
          
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.actionsWrapper}>
                
                {isLoading && <LoadingDots className={styles.dots} />}
                {!isLoading && <Button className='button-secondary' type='text' title='Export Answers CSV'  onClick={getDataHandler}/>}
                <CSVLink ref={csvLinkRef} headers={headers} data={data} filename={`${sessionName}_answers_Log.csv`} />
            </div>
        </div>
    )

}

export default CSVExportAnswers;