import React, {useState, useEffect, useContext} from 'react';

import ContentContainer from '../../shared/components/UIElements/ContentContainer';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import VMixUriItem from './VMixUriItem';

import styles from './VMixInfo.module.css';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';

const VMixInfo = ({ update }) => {

    const { REACT_APP_API_URL } = process.env;
    const { isLoading, sendRequest, error, clearError } = useHttpClient();
    const auth = useContext(AuthContext);
    const [sessions, setSessions] = useState([]);
    
    useEffect(() => {
        const fetchQuestions = async session => {
          try {
            const responseData = await sendRequest(
              `${REACT_APP_API_URL}/api/qa/qa-sessions`,
              'POST',
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token,
              }
            );

            if (responseData.sessions) {
                setSessions(responseData.sessions);
            }
          } catch (err) {
            console.log(err);
          }
        };
        fetchQuestions();
      }, [update]);

 return (
     <section id={styles.vmixInfoWrapper}>
      <ErrorModal error={error} clearError={clearError} />
      {isLoading && <LoadingSpinner />}
       {sessions && !isLoading && 
            <div className={styles.listWrapper}>
                    {sessions.map(session => <VMixUriItem key={session.id} session={session} />)}
            </div>
        }
     </section>
    )
}

export default VMixInfo;

