import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import Header from '../../shared/components/header/Header';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import ContentContainer from '../../shared/components/UIElements/ContentContainer';
import AddAnnouncment from '../components/AddAnnouncment';
import AnnounceList from '../components/AnnounceList';

import styles from './Announce.module.css';

const Announce = props => {
  const { REACT_APP_API_URL } = process.env;
  const history = useHistory();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const evid = useSelector(state => state.eid);
  //console.log(evid);

  const [announcements, setAnnouncements] = useState([]);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await sendRequest(
          `${REACT_APP_API_URL}/api/news/${evid}`,
          'POST',
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        console.log(response);
        if (response.result) {
          if (response.result.length > 0) {
            setAnnouncements(response.result);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (auth.isLoggedIn) {
      evid && fetchAnnouncements();
    } else {
      history.push("/auth");
    }
  }, [evid, update]);

  const updateList = () => {
    setUpdate(update ? false : true);
  };

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <div className={styles.pageWapper}>
        <Header title={'Live Announcements'} />
        <div className="page-content clear-header pc-stats">
          <div className={styles.pageContent}>

            {isLoading && <LoadingSpinner />}
            {!isLoading && announcements && (
              <AnnounceList announcements={announcements} update={updateList} />
            )}

            {!isLoading && (
              <ContentContainer classNameCC={styles.ccContainer} title="Add An Announcement">
                <AddAnnouncment eventId={evid} update={updateList} />
              </ContentContainer>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Announce;
