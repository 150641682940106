import React, { useEffect, useState, useContext } from 'react';
import { Doughnut } from 'react-chartjs-2';
import './DeviceDonut.css';

import { AuthContext } from '../../../shared/context/auth-context';
import { useHttpClient } from '../../../shared/hooks/http-hook';

const DeviceDonut = () => {

  const { REACT_APP_API_URL } = process.env;
  
  const { sendRequest } = useHttpClient();

  const auth = useContext(AuthContext);
  const [rawStats, setRawStats] = useState([]);
  const [filteredStats, setFilteredStats] = useState([]);
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [deviceTypeCount, setDeviceTypeCount] = useState([]);

  function removeDuplicates(originalArray, arrayKeys) {
    const filtered = originalArray.filter(
      (
        s => o =>
          (k => !s.has(k) && s.add(k))(arrayKeys.map(k => o[k]).join('|'))
      )(new Set())
    );

    console.log(filtered);
    return filtered;
  }

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const responseData = await sendRequest(
          `${REACT_APP_API_URL}/api/stats/device`,
          'POST',
          {
            'Content-Type': 'application/json',
            Authorization: auth.token,
          }
        );      
        if (responseData.stats ) {
          if (responseData.stats.length !== rawStats.length){
            setRawStats(responseData.stats);
            
            setFilteredStats(removeDuplicates(responseData.stats, ['userId', 'platform']));
          }
        }
       
      } catch (err) {
        console.log(err);
      }
    };
    fetchStats();
  }, []);

  useEffect(() => {

   var platformList = []

    filteredStats.map(item => (  
      platformList.push(item.platform)
    ))

    const counts = {};
    platformList.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });

    const deviceNames = Object.keys(counts);
    const deviceNumbers = Object.values(counts);
    setDeviceTypes(deviceNames);
    setDeviceTypeCount(deviceNumbers)

  }, [filteredStats]);

  return (
    <div className="pie-chart-container">
    {deviceTypes.length === 0 ? <div className='no-device-data-notice'><h1 className='no-device-data-title'>No device data to show yet</h1><p className='no-device-data-text'>Data will show when attendees start viewing your content.</p></div> :
      <Doughnut
        data={{
          labels: deviceTypes,
          datasets: [
            {
              data: deviceTypeCount,
              backgroundColor: [
                '#E07066',
                      '#D75245',
                      '#CD3525',
                      '#C21807',
                      '#B41404',
                      '#A51102',
                      '#960E00',
              ],
              borderColor: 'white',
              borderWidth: 2,
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
        }}
      />
    }
    </div>
  );
};

export default DeviceDonut;

