import React, { Fragment, useState, useContext } from 'react'


import { useHttpClient } from '../../shared/hooks/http-hook';
import Button from '../../shared/components/UIElements/Buttons/Button';
import Input from '../../shared/components/FormElements/Input';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import Spinner from '../../shared/components/UIElements/LoadingSpinner';
import { AuthContext } from '../../shared/context/auth-context';

import './UpdateStaffDetails.css';

const UpdateStaffPassword = ({staff}) => {

    const { REACT_APP_API_URL } = process.env;
  const { token, userId } = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [passMatch, setPassMatch] = useState(true);
  const [newPass, setNewPass] = useState();
  const [confPass, setConfPass] = useState();
  const [success, setSuccess] = useState();

    
      const onSubmit = async event => {
        event.preventDefault();

        try {
          const response = await sendRequest(
            `${REACT_APP_API_URL}/api/staff/manual-pass-update`,
            'PATCH',
            JSON.stringify({
              userId: userId,
              oldPass: event.target.currentPassword.value,
              newPass: event.target.passConfirm.value,
            }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            }
          );
          console.log(response);
          if (response.message)
          setConfPass('');
          setNewPass('');
          setSuccess(response.message)
        } catch (err) {
          //console.log(err);
        }
      };

      const newPassforTest = e => {
        setNewPass(e.target.value);
      }

      const comparePass = e => {
        if (e.target.value === newPass) {
          setPassMatch(true);
          setConfPass(e.target.value);
        } else {
          setPassMatch(false);
          setConfPass(e.target.value);
        }
      }

    return (
        <Fragment>
        <ErrorModal error={error} onClear={clearError} />
        {isLoading && (
            <div className="center spinner-margin-top">
              <Spinner 
                className='spinner-primary' 
                classNameText='spinner-text-primary'
                message='updating'
              />
            </div>
          )}
          {!isLoading && (
            <form onSubmit={onSubmit}>
              
              <Input
                id="currentPassword"
                name="currentPassword"
                type="password"
                groupFront="current password"
              />

              <Input
                id="password"
                name="password"
                type="password"
                value={newPass}
                onChange={newPassforTest}
                groupFront="new password"
              />
              {!passMatch && <span>Passwords do not match!</span>}
              <Input
                id="passConfirm"
                inputClassName={!passMatch && `invaild`}
                type="password"
                name="passConfirm"
                groupFront="confirm password"
                value={confPass}
                onChange={comparePass}
              />
              <p className="personal-settings-container-p">Must contain at least 8 characters</p>

              {success ? <h1>{success}</h1> 
              :
              <Button
                type="submit"
                className="button-primary personal-update-btn"
                title="Update Password"
              />}
            </form>
          )}
          </Fragment>
    )
}

export default UpdateStaffPassword;