import React, {useState, useEffect, useContext, useRef} from 'react'
import {CSVLink} from 'react-csv';
import Input from '../FormElements/Input';

import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';
import LoadingDots from '../UIElements/LoadingDots';
import ErrorModal from '../../components/UIElements/Modal/ErrorModal';
import styles from './CSVExportAttendance.module.css';
import Button from '../UIElements/Buttons/Button';

const headers = [
    {
        label: 'IP', 
        key: 'ip'
    },
    {
        label: 'Status', 
        key: 'connectionType'
    },
    {
        label: 'Date / Time', 
        key: 'createdAt'
    },
    {
        label: 'Name', 
        key: 'user.name'
    },
    {
        label: 'Organisation', 
        key: 'user.organization'
    },
    {
        label: 'Email', 
        key: 'user.email'
    },
    {
        label: 'Ticket Number', 
        key: 'user.ticketNo'
    },
    
];

const CSVExportAttendance = () => {

    const { REACT_APP_API_URL } = process.env;
    const { isLoading, sendRequest, error, clearError } = useHttpClient();
    const auth = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [downloadData, setDownLoadData] = useState([]);
    const [endDate, setEndDate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [showGetLogs, setShowGetLogs] = useState(true);
    const csvLinkRef = useRef();

    const getDataHandler = async () => {

        if (startDate && endDate) {
            if (startDate < endDate ) {
                try {
                    const responseData = await sendRequest(
                      `${REACT_APP_API_URL}/api/conn/filtered`,
                      'POST',
                      JSON.stringify({
                        startDate,
                        endDate
                    }),
                    {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + auth.token,
                    }
                    );
                    if (responseData){
                        console.log(responseData);
                        setData(responseData)
                        setShowGetLogs(false);
                        
                    }
                  } catch (err) {
                    console.log(err);
                  }
            } else {
                //alert - Start date time  needs to be prior to end date time
            }
        } else {
            //alert you need a start date and end date
        }
    }

    const startDateOnChangeHandler = event => {
        console.log(event.target.value);
        setStartDate(event.target.value);
    }

    const endDateOnChangeHandler = event => {
        console.log(event.target.value);
        setEndDate(event.target.value);
    }

    const downloadCSVHandler = () => {
        setDownLoadData(()=>[...data]);
        csvLinkRef.current.link.click();
        setShowGetLogs(true);
    }


    return (
        <div className={styles.mainWrapper}>
        
        <h1 className={styles.mainTitle}>CSV Attendance Report</h1>
        
        <div className={styles.actionsWrapper}>
            <div className={styles.inputWrapper}>
                <label>Start Date/time</label>
                <Input 
                    id='startDateTime' 
                    name='startDateTime'  
                    type='datetime-local'
                    inputClassName={`no-group`}
                    onChange={startDateOnChangeHandler}
                />
            </div>
            <div className={styles.inputWrapper}>
                <label>End Date/time</label>
                <Input 
                    id='endDateTime' 
                    name='endDateTime'  
                    type='datetime-local'
                    inputClassName={`no-group`}
                    onChange={endDateOnChangeHandler}
                />
            </div>
            
            {isLoading && <LoadingDots />}
            {!isLoading && <Button type='text' title='Get Logs'  onClick={getDataHandler}/>}
            {!showGetLogs && <Button className='button-secondary' type='text' title='download'  onClick={downloadCSVHandler}/>}
            <CSVLink ref={csvLinkRef} headers={headers} data={data} filename={'Attendance_Log.csv'} />
        </div>
        <div className={styles.logBody}>
        {data && data.map((log, idx) => (
            <div className={styles.row} key={idx}>
                <p>{log.ip}</p>
                <p>{log.connectionType}</p>
                <p>{log.createdAt}</p>
                <p>{log.user.name}</p>
                <p>{log.user.organization}</p>
                <p>{log.user.email}</p>
                <p>{log.user.ticketNo}</p>

            </div>
        ))}
        {data.length === 0 && 
            <div className={styles.nologs}>
                <h1>No Logs Selected</h1>
                <p>Select a date ranage of the attendance logs you want to see</p>
            </div>
        }

        </div>

       

        
        </div>
    )

}

export default CSVExportAttendance;