import React, {Fragment, useEffect, useState } from 'react';

import Input from '../../shared/components/FormElements/Input';
import Card from '../../shared/components/UIElements/Card';
import VisitorItem from './VisitorItem';

import './VisitorList.css';

const VisitorList = ({ attendees, selectVisitorHandler, update, eventId }) => {

  const [visitors, setVisitors] = useState([]);
  const [visitorList, setVisitorList] = useState([]);

  useEffect(() => {
    setVisitors(attendees);
  }, [attendees]);

  useEffect(() => {
    setVisitorList(visitors);
  }, [visitors]);

  const submitHandler = event => {
    event.preventDefault();
    let result;

    if (event.target.email.value) {
      if (result) {
        const update = visitors.filter(v =>
          v.email.includes(event.target.email.value)
        );
        if (update.length > 0) {
          result = [...result, ...update];
        }
      } else {
        result = visitors.filter(v =>
          v.email.includes(event.target.email.value)
        );
      }
    }

    if (!result) {
      setVisitorList(visitors);
    } else if (result.length > 0) {
      setVisitorList(result);
    }
  };

  const selectHandler = id => {
    selectVisitorHandler(id);
  };

  return (
    <Fragment>
        <Card className={'visitor-list-card'}>
          <div className="visitor-list-content">
            <div className="visitor-list-filter">
              <form onSubmit={submitHandler}>
                <Input
                  id="email"
                  name="email"
                  type="text"
                  placeholder="email"
                  className="search-input  no-group"
                  wrapperClassName="input-search-wrapper"
                />

                <button className="search-button" type="submit">
                  filter
                </button>
              </form>
            </div>
            <div className="visitor-list">
              {visitorList.length === 0 ? (
                <h2>No Visitors found</h2>
              ) : (
                visitorList.map(visitor => (
                  <VisitorItem
                    key={visitor.id}
                    visitor={visitor}
                    eventId={eventId}
                    update={update}
                    selectedVisitor={selectHandler}
                  />
                ))
              )}
            </div>
          </div>
        </Card>
    </Fragment>
  );
};

export default VisitorList;
