import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';

import Input from '../FormElements/Input';
import Button from '../UIElements/Buttons/Button';
import LoadingSpinner from '../UIElements/LoadingSpinner';
import ErrorModal from '../UIElements/Modal/ErrorModal';

import './CreateEventForm.css';

const UpdateLiveStream = () => {

    const { REACT_APP_API_URL } = process.env;
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    const evid = useSelector(state => state.eid);
    const [streamUri, setStreamUri] = useState('');
    const [streamLabel, setStreamLabel] = useState('required');
    const [streamId, setStreamId] = useState();

    useEffect(() => {
     if (evid) {
       const fecthStreams = async () => {
        try {
          const response = await sendRequest(
            `${REACT_APP_API_URL}/api/streams/`,
            'POST',
            JSON.stringify({
              eventId: evid
            }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token,
            }
          );
          setStreamId(response.streams[0].id);
          setStreamUri(response.streams[0].uri);
          setStreamLabel(response.streams[0].label);

        } catch (err) {
          console.log(err);
        }

       }
       fecthStreams();
     }
    }, [])

    const onSubmit = async (event) => {
     event.preventDefault()
    
        clearError();

        //console.log(evid);
        console.log(streamId);
        console.log(event.target.streamUri.value);
        console.log(event.target.label.value);
        

        
          try {
            const response = await sendRequest(
              `${REACT_APP_API_URL}/api/streams/update`,
              'PATCH',
              JSON.stringify({
                eventId: evid,
                id: streamId,
                streamURI: event.target.streamUri.value,
                label: event.target.label.value,
                lang: 'en'
              }),
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token,
              }
            );
            console.log(response);
            
          } catch (err) {
            console.log(err);
          }
        
      };

      const streamChangeHandler = event => {
        setStreamUri(event.target.value);
      }

      const labelChangeHandler = event => {
        setStreamLabel(event.target.value);
      }

      return (
          <div className='create-event-form-wrapper'>
            <ErrorModal error={error} onClear={clearError} />
            {isLoading && <LoadingSpinner />}
            {!isLoading && 
                <form onSubmit={onSubmit}>
                <Input
                    id="streamUri"
                    name="streamUri"
                    type="text"
                    value={streamUri}
                    onChange={streamChangeHandler}
                    groupFront="Live Stream URI:"
                />

                <Input
                    id="label"
                    name="label"
                    type="text"
                    value={streamLabel}
                    onChange={labelChangeHandler}
                    groupFront="Stream Label: "
                />
                
                <div className='create-event-actions'>
                    <Button title='save' className='button-secondary' />
                </div>
                </form>
            }
          </div>
      )
}

export default UpdateLiveStream;